import React, { memo, useCallback, useState, useEffect } from 'react';
import { MDBRow, MDBCol, MDBTextArea, MDBInputGroup, MDBInput, MDBBtn, MDBTabsPane, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { convertHTMlToRaw } from "../../../utils/HTMLDraft";
import { ContentState, convertFromHTML } from 'draft-js';

const BulletPointInput = memo(({ bulletPoint, index, onUpdate, onRemove }) => {
  const { t } = useTranslation();
  
  const handleGermanTextChange = useCallback((value) => {
    const updatedTexts = bulletPoint.text.map(t => 
      t.language === 'de' ? { ...t, text: value } : t
    );
    onUpdate(index, { text: updatedTexts });
  }, [bulletPoint.text, index, onUpdate]);

  const handleEnglishTextChange = useCallback((value) => {
    const updatedTexts = bulletPoint.text.map(t => 
      t.language === 'en' ? { ...t, text: value } : t
    );
    onUpdate(index, { text: updatedTexts });
  }, [bulletPoint.text, index, onUpdate]);

  const handleRemove = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);


  return (
    <MDBRow className="mb-3">
      <MDBCol md="5">
        <MDBInputGroup>
          <div className="input-group-text">
            <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBInput
            type="text"
            value={bulletPoint.text.find(t => t.language === 'de')?.text || ''}
            onChange={(e) => handleGermanTextChange(e.target.value)}
            label={t('Bullet Point (German)')}
          />
        </MDBInputGroup>
      </MDBCol>
      <MDBCol md="5">
        <MDBInputGroup>
          <div className="input-group-text">
            <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBInput
            type="text"
            value={bulletPoint.text.find(t => t.language === 'en')?.text || ''}
            onChange={(e) => handleEnglishTextChange(e.target.value)}
            label={t('Bullet Point (English)')}
          />
        </MDBInputGroup>
      </MDBCol>
      <MDBCol md="2">
        <MDBBtn 
          color="danger" 
          size="sm"
          onClick={handleRemove}
        >
          {t('Remove')}
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  );
});

BulletPointInput.displayName = 'BulletPointInput';

const TextsTab = ({ event, onInputChange }) => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState('general');
  
  const handleTabClick = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  const handleBulletPointUpdate = useCallback((index, updatedBulletPoint) => {
    const updatedBulletPoints = [...(event.bookingTicketBulletPoints || [])];
    updatedBulletPoints[index] = updatedBulletPoint;
    onInputChange('bookingTicketBulletPoints', updatedBulletPoints);
  }, [event.bookingTicketBulletPoints, onInputChange]);

  const handleBulletPointRemove = useCallback((index) => {
    const updatedBulletPoints = event.bookingTicketBulletPoints.filter((_, i) => i !== index);
    onInputChange('bookingTicketBulletPoints', updatedBulletPoints);
  }, [event.bookingTicketBulletPoints, onInputChange]);

  const handleAddBulletPoint = useCallback(() => {
    const newBulletPoint = {
      text: [
        { language: 'de', text: '' },
        { language: 'en', text: '' }
      ]
    };
    const updatedBulletPoints = [...(event.bookingTicketBulletPoints || []), newBulletPoint];
    onInputChange('bookingTicketBulletPoints', updatedBulletPoints);
  }, [event.bookingTicketBulletPoints, onInputChange]);

  // Initialize editor states for all WYSIWYG fields
  const [editorStates, setEditorStates] = useState({
    ticketTAC: {
      de: null,
      en: null,
    },
    ticketPrivacyPolicy: {
      de: null,
      en: null,
    },
    boothTAC: {
      de: null,
      en: null,
    },
    boothPrivacyPolicy: {
      de: null,
      en: null,
    },
    landingPageIntroText: {
      de: null,
      en: null,
    },
    landingPageProgramText: {
      de: null,
      en: null,
    },
    landingPageProgramSpeakersText: {
      de: null,
      en: null,
    },
    landingPageBookingText: {
      de: null,
      en: null,
    },
    landingPageBookingPandCText: {
      de: null,
      en: null,
    },
  });

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (event && !isInitialized) {
      const initEditorState = (content) => {
        if (!content) return EditorState.createEmpty();
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(content).contentBlocks,
          convertFromHTML(content).entityMap
        );
        return EditorState.createWithContent(contentState);
      };

      setEditorStates({
        ticketTAC: {
          de: initEditorState(event.ticketTAC?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.ticketTAC?.find(t => t.language === 'en')?.text),
        },
        ticketPrivacyPolicy: {
          de: initEditorState(event.ticketPrivacyPolicy?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.ticketPrivacyPolicy?.find(t => t.language === 'en')?.text),
        },
        boothTAC: {
          de: initEditorState(event.boothTAC?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.boothTAC?.find(t => t.language === 'en')?.text),
        },
        boothPrivacyPolicy: {
          de: initEditorState(event.boothPrivacyPolicy?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.boothPrivacyPolicy?.find(t => t.language === 'en')?.text),
        },
        landingPageIntroText: {
          de: initEditorState(event.landingPageIntroText?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.landingPageIntroText?.find(t => t.language === 'en')?.text),
        },
        landingPageProgramText: {
          de: initEditorState(event.landingPageProgramText?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.landingPageProgramText?.find(t => t.language === 'en')?.text),
        },
        landingPageProgramSpeakersText: {
          de: initEditorState(event.landingPageProgramSpeakersText?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.landingPageProgramSpeakersText?.find(t => t.language === 'en')?.text),
        },
        landingPageBookingText: {
          de: initEditorState(event.landingPageBookingText?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.landingPageBookingText?.find(t => t.language === 'en')?.text),
        },
        landingPageBookingPandCText: {
          de: initEditorState(event.landingPageBookingPandCText?.find(t => t.language === 'de')?.text),
          en: initEditorState(event.landingPageBookingPandCText?.find(t => t.language === 'en')?.text),
        },
      });
      setIsInitialized(true);
    }
  }, [event, isInitialized]);

  // Generic handler for editor state changes
  const handleEditorStateChange = (editorState, field, language) => {
    setEditorStates(prevStates => ({
      ...prevStates,
      [field]: {
        ...prevStates[field],
        [language]: editorState
      }
    }));

    const updatedContent = [...(event[field] || [])];
    const contentIndex = updatedContent.findIndex(item => item.language === language);
    const newContent = {
      language,
      text: convertHTMlToRaw(editorState)
    };

    if (contentIndex === -1) {
      updatedContent.push(newContent);
    } else {
      updatedContent[contentIndex] = newContent;
    }

    onInputChange(field, updatedContent);
  };

  const renderEditorSection = (title, field) => (
    <>
      <h5 className="mb-3">{t(title)}</h5>
      <MDBRow className="mb-4">
        <MDBCol md="6">
          <MDBInputGroup className="mb-3">
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <div className="w-100">
              <Editor
                editorState={editorStates[field].de}
                onEditorStateChange={(state) => handleEditorStateChange(state, field, 'de')}
                editorClassName="form-control"
                handlePastedText={() => false}
              />
            </div>
          </MDBInputGroup>
        </MDBCol>
        <MDBCol md="6">
          <MDBInputGroup>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
            </div>
            <div className="w-100">
              <Editor
                editorState={editorStates[field].en}
                onEditorStateChange={(state) => handleEditorStateChange(state, field, 'en')}
                editorClassName="form-control"
                handlePastedText={() => false}
              />
            </div>
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>
    </>
  );

  return (
    <>
      <MDBTabs className='mb-3'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('general')} active={activeTab === 'general'}>
            {t('General')}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('website')} active={activeTab === 'website'}>
            {t('Website')}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('exhibitor')} active={activeTab === 'exhibitor'}>
            {t('Exhibitor Booking')}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('visitor')} active={activeTab === 'visitor'}>
            {t('Visitor Booking')}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('ticket')} active={activeTab === 'ticket'}>
            {t('Ticket')}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('lectures')} active={activeTab === 'lectures'}>
            {t('Lectures')}
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleTabClick('legal')} active={activeTab === 'legal'}>
            {t('Terms & Privacy')}
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        {/* General Tab */}
        <MDBTabsPane open={activeTab === 'general'}>
          <h5 className="mb-3">{t("Description")}</h5>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Description (German)')}
                  value={event?.description?.find(d => d.language === 'de')?.text || ''}
                  onChange={(e) => onInputChange('description', e.target.value, 'de')}
                />
              </MDBInputGroup>
            </MDBCol>
            <MDBCol md="6">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Description (English)')}
                  value={event?.description?.find(d => d.language === 'en')?.text || ''}
                  onChange={(e) => onInputChange('description', e.target.value, 'en')}
                />
              </MDBInputGroup>
            </MDBCol>
          </MDBRow>
        </MDBTabsPane>
        {/* Website Tab */}
        <MDBTabsPane open={activeTab === 'website'}>
          {renderEditorSection("Introduction Text", "landingPageIntroText")}
          {renderEditorSection("Program Text", "landingPageProgramText")}
          {renderEditorSection("Program Speakers Text", "landingPageProgramSpeakersText")}
          {renderEditorSection("Booking Text", "landingPageBookingText")}
          {renderEditorSection("Booking Payment & Cancelation Text", "landingPageBookingPandCText")}
        </MDBTabsPane>

        {/* Exhibitor Booking Tab */}
        <MDBTabsPane open={activeTab === 'exhibitor'}>
          {/* Booking Notice Section */}
          <h5 className="mb-3">{t("Booking Notice")}</h5>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Booking Notice (German)')}
                  value={event?.bookingNotice?.find(n => n.language === 'de')?.text || ''}
                  onChange={(e) => onInputChange('bookingNotice', e.target.value, 'de')}
                />
              </MDBInputGroup>
            </MDBCol>
            <MDBCol md="6">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Booking Notice (English)')}
                  value={event?.bookingNotice?.find(n => n.language === 'en')?.text || ''}
                  onChange={(e) => onInputChange('bookingNotice', e.target.value, 'en')}
                />
              </MDBInputGroup>
            </MDBCol>
          </MDBRow>
        </MDBTabsPane>

        {/* Visitor Booking Tab */}
        <MDBTabsPane open={activeTab === 'visitor'}>
          <h5 className="mt-4">{t("Booking Ticket Bullet Points")}</h5>
          <MDBRow>
            <MDBCol>
              {event?.bookingTicketBulletPoints?.map((bulletPoint, index) => (
                <BulletPointInput
                  key={`${index}-${bulletPoint.text[0]?.text}-${bulletPoint.text[1]?.text}`}
                  bulletPoint={bulletPoint}
                  index={index}
                  onUpdate={handleBulletPointUpdate}
                  onRemove={handleBulletPointRemove}
                />
              ))}
              <MDBBtn onClick={handleAddBulletPoint}>
                {t('Add Bullet Point')}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBTabsPane>

        {/* Ticket Tab */}
        <MDBTabsPane open={activeTab === 'ticket'}>
          {/* Ticket Notice Section */}
          <h5 className="mb-3">{t("Ticket Notice")}</h5>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Ticket Notice (German)')}
                  value={event?.ticketNotice?.find(n => n.language === 'de')?.text || ''}
                  onChange={(e) => onInputChange('ticketNotice', e.target.value, 'de')}
                />
              </MDBInputGroup>
            </MDBCol>
            <MDBCol md="6">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Ticket Notice (English)')}
                  value={event?.ticketNotice?.find(n => n.language === 'en')?.text || ''}
                  onChange={(e) => onInputChange('ticketNotice', e.target.value, 'en')}
                />
              </MDBInputGroup>
            </MDBCol>
          </MDBRow>

          {/* Ticket Organizer Line Section */}
          <h5 className="mb-3">{t("Ticket Organizer Line")}</h5>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBInput
                  label={t("Ticket Organizer Line (German)")}
                  value={event?.ticketOrganizerLine?.find(n => n.language === 'de')?.text || ''}
                  onChange={(e) => onInputChange('ticketOrganizerLine', e.target.value, 'de')}
                />
              </MDBInputGroup>
            </MDBCol>
            <MDBCol md="6">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBInput
                  label={t("Ticket Organizer Line (English)")}
                  value={event?.ticketOrganizerLine?.find(n => n.language === 'en')?.text || ''}
                  onChange={(e) => onInputChange('ticketOrganizerLine', e.target.value, 'en')}
                />
              </MDBInputGroup>
            </MDBCol>
          </MDBRow>
        </MDBTabsPane>

        {/* Lectures Tab */}
        <MDBTabsPane open={activeTab === 'lectures'}>
          <h5 className="mb-3">{t("Lectures Notice")}</h5>
          <MDBRow className="mb-4">
            <MDBCol md="6">
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Lectures Notice (German)')}
                  value={event?.lecturesNotice?.find(n => n.language === 'de')?.text || ''}
                  onChange={(e) => onInputChange('lecturesNotice', e.target.value, 'de')}
                />
              </MDBInputGroup>
            </MDBCol>
            <MDBCol md="6">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
                </div>
                <MDBTextArea
                  label={t('Lectures Notice (English)')}
                  value={event?.lecturesNotice?.find(n => n.language === 'en')?.text || ''}
                  onChange={(e) => onInputChange('lecturesNotice', e.target.value, 'en')}
                />
              </MDBInputGroup>
            </MDBCol>
          </MDBRow>
        </MDBTabsPane>

        {/* Terms & Privacy Tab */}
        <MDBTabsPane open={activeTab === 'legal'}>
          {renderEditorSection("Ticket Terms and Conditions", "ticketTAC")}
          {renderEditorSection("Ticket Privacy Policy", "ticketPrivacyPolicy")}
          {renderEditorSection("Booth Terms and Conditions", "boothTAC")}
          {renderEditorSection("Booth Privacy Policy", "boothPrivacyPolicy")}
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  );
};

export default TextsTab;