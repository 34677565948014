import { useEffect, useRef, useState, Fragment } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBInput, MDBRow, MDBSelect, MDBTable, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane, MDBTypography } from "mdb-react-ui-kit";
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from "react-i18next";
import { useContext } from 'react';
import { UserContext } from '../../App';
import shortUUID from 'short-uuid';
import {generateClient, get} from 'aws-amplify/api';
import QRCode from 'qrcode';
import {pdf, PDFViewer , Page, Text, View, Image, Document, StyleSheet, Font, BlobProvider } from '@react-pdf/renderer';
import VCard from 'vcard-creator'
import SairaFront from '../../scss/fonts/SairaSemiCondensed-Regular.ttf';
import SairaFontBold from '../../scss/fonts/SairaSemiCondensed-Bold.ttf';
import logo from "./logo_pos_inspire.jpg"
import getEventDays from "../../utils/getEventDays";
import { isoToGermanDate } from "../../utils/dateTools";
import { getLocalizedText } from "../../utils/localizedText";

export default function PrintTicket() {
  const { user } = useContext(UserContext);
  const client = generateClient();
  const { t, i18n } = useTranslation();

  const [scanDevice, setScanDevice] = useState("");

  const [ticketData, setTicketData] = useState({
    firstName: "",
    lastName: "",
    company: "",
  });
  const [userData, setUserData] = useState({});
  const [ticketId, setTicketId] = useState("");
  const [pdfContent, setPdfContent] = useState(null);
  const [autoPrint, setAutoPrint] = useState(false);
  const [ticketIdError, setTicketIdError] = useState("");

  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);

  const getEvents = async () => {
    try {
        const events = await client.graphql({
            query: /* GraphQL */ `
            query ListEvents {
                listEvents {
                    items {
                        id
                        name {
                            text
                            language
                        }
                        startDate
                        endDate
                        status
                    }
                }
            }
            `,
            variables: {
                filter: {
                    startDate: {
                        ge: new Date().toISOString().substring(0, 10)
                    }
                }
            }
        });
        setEvents(events.data.listEvents.items.filter(event => event.status === "active"));
        return events.data.listEvents.items;
    } catch (error) {
        console.log("error on fetching events", error);
    }
  }

  useEffect(() => {
    getEvents();
  }, []);

  const getTicketData = async () => {
    try {
      let ticketIdUUIDType = null;
      // Test if ticketId is uuid or short uuid
      try {
        if (shortUUID().fromUUID(ticketId)) {
          ticketIdUUIDType = "uuid";
          setTicketIdError("");
        } else if (shortUUID().fromUUID(shortUUID().toUUID(ticketId))) {
          ticketIdUUIDType = "shortuuid";
          setTicketIdError("");
        }
      } catch (error) {
        console.error(error);
        setTicketIdError("Invalid Ticket ID");
      }
      console.log(ticketIdUUIDType);
      const data = await client.graphql({
        query: /* GraphQL */ `
        query GetTicket($id: ID!) {
          getTicket(id: $id) {
            owner
            id
            event {
              id
            }
            createdByUserId
            userTicketsId
            eventDays
            status
            paymentStatus
            type
            visitorType
            addons
            notes
            user {
              email
              contact {
                phone
              }
            }
            personalAddress {
              firstName
              lastName
              title
              salutation
              street
              street2
              streetNumber
              company
              contactPerson
              city
              zip
              countryCode
              __typename
            }
            billingAddress {
              firstName
              lastName
              title
              salutation
              street
              street2
              streetNumber
              company
              contactPerson
              city
              zip
              countryCode
              __typename
            }
            billingEmail
            invitationCode
            orderNumber
            createdAt
            updatedAt
            ticketEventId
            ticketCompanyId
            ticketPaymentId
            scans {
              items {
                scannedAt
              }
            }
            __typename
          }
        }
      `,
        variables: { id: ticketIdUUIDType === "uuid" ? ticketId : shortUUID().toUUID(ticketId) },
      });
      console.log(data);
      const ticket = 
        {
          firstName: data.data.getTicket.personalAddress.firstName,
          lastName: data.data.getTicket.personalAddress.lastName,
          title: data.data.getTicket.personalAddress.title || "",
          company: data.data.getTicket.personalAddress.companyName || data.data.getTicket.personalAddress.company || data.data.getTicket.billingAddress?.company || "",
          eventDays: data.data.getTicket.eventDays,
          email: data.data.getTicket.user?.email || "",
          phone: data.data.getTicket.user?.contact?.phone || "",
          visitorType: data.data.getTicket.visitorType,
        }
      
      setTicketData(ticket);

      if(autoPrint) {
        const content = await generatePDFContent(ticket);
        printTicket(content);
      }

      createTicketScan(data.data.getTicket.id);
      setTicketId("");
    } catch (error) {
      console.error(error);
    }
  };

  const createTicketScan = async (ticketId) => {
    try {
      const data = await client.graphql({
        query: /* GraphQL */ `
        mutation CreateTicketScan(
          $input: CreateTicketScanInput!
          $condition: ModelTicketScanConditionInput
        ) {
          createTicketScan(input: $input, condition: $condition) {
            id
            scannedAt
            scannedLocation
            scannedDevice
            createdAt
            updatedAt
            ticketScansId
          }
        }
      `,
        variables: {
          input: {
            ticketScansId: ticketId,
            scannedAt: new Date().toISOString(),
            scannedLocation: "Lounges 2024 Karlsruhe",
            scannedDevice: scanDevice,
          },
        },
      });
      //console.log(data);
    } catch (error) {
      console.error(error);
    }
  };


  const printTicket = async (PDFDocument) => {
    const blob = await pdf(PDFDocument).toBlob();  // Generiert ein Blob aus dem PDFDocument
    const url = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.onload = () => {
      iframe.contentWindow.print();
    };
  }
  const generateVCard = (ticketData) => {
    const vCard = new VCard();
    vCard.addName(ticketData.lastName, ticketData.firstName);
    vCard.addCompany(ticketData.company);
    if(ticketData.email) {
      vCard.addEmail(ticketData.email);
    }
    if(ticketData.phone) {
      vCard.addPhoneNumber(ticketData.phone);
    }
    return vCard.toString();
  }

  Font.register({ family: 'SairaBold', src: SairaFontBold });
  Font.register({ family: 'Saira', src: SairaFront });

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#fff',
      textAlign: 'center',
      fontFamily: "Saira"
    },
    section: {
      marginTop: "105mm",
      padding: 10,
      flexGrow: 1
    },
    section2: {
      marginBottom: "85mm",
      padding: 10,
      flexGrow: 1
    },
    nameText: {
      fontSize: "22mm",
      fontFamily: "SairaBold"
    },
    companyText: {
      fontSize: ticketData.company.length < 30 ? "18mm" : "14mm",
    },
    daysText: {
      fontSize: "16mm",
    },
    flipText : {
      transform: "rotate(180deg)",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      flexWrap: "nowrap",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 35
    },
    cell: {
      alignContent: "center",
      // borderColor: "#cc0000",
      // borderStyle: "solid",
      // borderWidth: 2,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      height: "350px",
      width: "400px",
    },

  });

  const generatePDFContent = async (ticketData) => {
    const qrCode = await QRCode.toDataURL(generateVCard(ticketData), {scale: 1});
    const content = (
    <Document>
      <Page style={styles.page} size={
        {
          width: 800, 
          height: 2000,
        }
      }>
        <View style={styles.section}>
          <Text style={styles.nameText}>
            {ticketData.title && ticketData.title.length < 10 ? ticketData.title + " " : ""}{ticketData.firstName} {ticketData.lastName}
          </Text>
          <Text style={styles.companyText}>
            {ticketData.company}
          </Text>
          <View style={styles.row}>
            <View style={[styles.cell]}>
              {qrCode && (ticketData.phone || ticketData.email) && <Image style={{width: "120mm", marginLeft: "60px"}} src={qrCode} />}
            </View>
            <View style={[styles.cell, {display: "flex", justifyContent: "center"}]}>
              {ticketData.eventDays?.sort((a,b) => new Date(a) - new Date(b)).map((day) => {
                return <Text style={styles.daysText}>{new Date(day).toLocaleDateString("de-DE").substring(0,5)}</Text>;
              })}
            </View>
          </View>
         
          <View style={[styles.section2, styles.flipText]}>
          <Text style={styles.nameText}>
          {ticketData.title && ticketData.title.length < 10 ? ticketData.title + " " : ""}{ticketData.firstName} {ticketData.lastName}
          </Text>
          <Text style={styles.companyText}>
            {ticketData.company}
          </Text>
          <View style={styles.row}>
            <View style={[styles.cell]}>
            {qrCode && (ticketData.phone || ticketData.email) && <Image style={{width: "120mm", marginLeft: "60px"}} src={qrCode} />}
            </View>
            <View style={[styles.cell, {display: "flex", justifyContent: "center"}]}>
              {ticketData.eventDays?.sort((a,b) => new Date(a) - new Date(b)).map((day) => {
                return <Text style={styles.daysText}>{new Date(day).toLocaleDateString("de-DE").substring(0,5)}</Text>;
              })}
            </View>
          </View>
         
        </View>
        
         

         
        </View>
        
      </Page>
    </Document>
    );
    setPdfContent(content);
    return content;
  }

  useEffect(() => {
    if(Object.keys(ticketData).length > 0) {
      generatePDFContent(ticketData);
    }
  }, [ticketData]);


  const [basicActive, setBasicActive] = useState('print');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  // Tab Search Ticket

  const [searchString, setSearchString] = useState("");
  const [searchedTickets, setSearchedTickets] = useState([]);

  const searchTicket = async (searchString) => {
    try {
      const data = await client.graphql({
        query: /* GraphQL */ `
        query SearchTickets(
          $filter: SearchableTicketFilterInput
          $sort: [SearchableTicketSortInput]
          $limit: Int
          $nextToken: String
          $from: Int
          $aggregates: [SearchableTicketAggregationInput]
        ) {
          searchTickets(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
            aggregates: $aggregates
          ) {
            items {
              id
              createdByUserId
              userTicketsId
              ticketEventId
              eventDays
              status
              type
              visitorType
              notes
              personalAddress {
                firstName
                lastName
                title
                company
              }
              user {
                email
                contact {
                  phone
                }
              }
              firstName
              lastName
              companyName
              invitationCode
              createdAt
              updatedAt
              __typename
            }
          }
        }
      `,
        variables: { filter: {
          or: [
            {firstName: {matchPhrasePrefix: searchString}},
            {lastName: {matchPhrasePrefix: searchString}},
            {companyName: {matchPhrasePrefix: searchString}}
          ],
          ticketEventId: {eq: selectedEventId}
          }
        },
      });
      console.log(data);
      setSearchedTickets(data.data.searchTickets.items);
    }
    catch (error) {
      console.error(error);
    }
  }


  // Tab Book Ticket

  const [bookTicketData, setBookTicketData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    zip: "",
    city: "",
    country: "",
    eventDays: [],
  });

  const [newTicketData, setNewTicketData] = useState({
    // "owner": "c5caf7a3-f5bd-40a0-b6de-1c8118c1c4e6",
    //         "id": "beedd4fb-1262-44a4-ac24-530af8f3e485",
    //         "event": {
    //             "id": "882c49b6-ba4b-4c66-8a35-987d04af08a5"
    //         },
    //         "createdByUserId": "c60b1ad6-5f75-43c8-8911-ec70bc372be2",
    //         "userTicketsId": "c60b1ad6-5f75-43c8-8911-ec70bc372be2",
    //         "eventDays": [
    //             "2024-04-24", "2024-04-25"
    //         ],
    //         "status": null,
    //         "paymentStatus": null,
    //         "type": "visitor",
    //         "visitorType": null,
    //         "addons": null,
    //         "notes": null,
    //         "user": {
    //             "email": "baum@channelhive.com",
    //             "contact": {
    //                 "phone": null
    //             }
    //         },
    //         "personalAddress": {
    //             "firstName": "Marvin",
    //             "lastName": "Baum",
    //             "title": null,
    //             "salutation": null,
    //             "street": null,
    //             "street2": null,
    //             "streetNumber": null,
    //             "company": "ChannelHive Marvin Baum",
    //             "contactPerson": null,
    //             "city": "Heidelberg",
    //             "zip": "69115",
    //             "countryCode": "Germany",
    //             "__typename": "FullAddress"
    //         },
    //         "billingAddress": null,
    //         "billingEmail": null,
    //         "invitationCode": null,
    //         "orderNumber": null,
    //         "createdAt": "2024-04-21T19:00:16.548Z",
    //         "updatedAt": "2024-04-21T19:00:16.548Z",
    //         "ticketEventId": "882c49b6-ba4b-4c66-8a35-987d04af08a5",
    //         "ticketCompanyId": null,
    //         "ticketPaymentId": null,
    //         "__typename": "Ticket"
  });

  const createTicket = async () => {
      try {
        const ticketData = await client.graphql({
          query: /* GraphQL */ `
          mutation CreateTicket(
            $input: CreateTicketInput!
            $condition: ModelTicketConditionInput
          ) {
            createTicket(input: $input, condition: $condition) {
              owner
            id
            event {
              id
            }
            createdByUserId
            userTicketsId
            eventDays
            status
            paymentStatus
            type
            visitorType
            addons
            notes
            user {
              email
              contact {
                phone
              }
            }
            personalAddress {
              firstName
              lastName
              title
              salutation
              street
              street2
              streetNumber
              company
              contactPerson
              city
              zip
              countryCode
              __typename
            }
            billingAddress {
              firstName
              lastName
              title
              salutation
              street
              street2
              streetNumber
              company
              contactPerson
              city
              zip
              countryCode
              __typename
            }
            billingEmail
            invitationCode
            orderNumber
            createdAt
            updatedAt
            ticketEventId
            ticketCompanyId
            ticketPaymentId
            __typename
            }
          }
        `,
          variables: {
            input: {
              type: "visitor",
              eventDays: bookTicketData.eventDays,
              userTicketsId: user.id,
              createdByUserId: user.id,
              ticketEventId: "882c49b6-ba4b-4c66-8a35-987d04af08a5",
              invitationCode: bookTicketData.invitationCode,
              personalAddress: {
                firstName: bookTicketData.firstName,
                lastName: bookTicketData.lastName,
                street: bookTicketData.street,
                streetNumber: bookTicketData.streetNumber,
                company: bookTicketData.company,
                zip: bookTicketData.zip,
                city: bookTicketData.city,
                countryCode: bookTicketData.country,
              },
            }
          }
        });
        console.log("Created Ticket");
        return ticketData.data.createTicket;
        //console.log(ticketData);
      } catch (error) {
        console.error(error);
        
      }
  }


  const handleBookTicket = async () => {
    try {
      const data = await createTicket();
      setNewTicketData(data);
      getNextDocumentId();
    }
    catch (error) {
      console.error(error);
    }
  }

  const resetBookTicket = () => {
    setBookTicketData({
      firstName: "",
      lastName: "",
      company: "",
      zip: "",
      city: "",
      country: "",
      eventDays: [],
    });
    setNewTicketData({});
  }

  const [pdfContentReceipt, setPdfContentReceipt] = useState(null);

  const stylesReceipt = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#fff',
      textAlign: 'center',
      fontFamily: "Saira"
    },
    section: {
      marginTop: "105mm",
      padding: 10,
      flexGrow: 1
    },
    section2: {
      marginBottom: "85mm",
      padding: 10,
      flexGrow: 1
    },
    nameText: {
      fontSize: "22mm",
      fontFamily: "SairaBold"
    },
    companyText: {
      fontSize: "14mm",
    },
    daysText: {
      fontSize: "16mm",
    },
    flipText : {
      transform: "rotate(180deg)",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
      flexWrap: "nowrap",
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: 35
    },
    cell: {
      alignContent: "center",
      // borderColor: "#cc0000",
      // borderStyle: "solid",
      // borderWidth: 2,
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: "auto",
      height: "350px",
      width: "400px",
    },

  });

  const Invoice = async () => {
    const qrCode = await QRCode.toDataURL(shortUUID().fromUUID(newTicketData?.id), {scale: 1});
    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 20,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column' },

        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },

        titleContainer: {flexDirection: 'row',marginTop: 24},
        
        logo: { width: 90 },

        reportTitle: {  fontSize: 16,  textAlign: 'center' },

        addressTitle : {fontSize: 11,fontStyle: 'bold', textAlign: 'center'}, 
        
        invoice : {fontWeight: 'bold',fontSize: 20},
        
        invoiceNumber : {fontSize: 11,fontWeight: 'bold'}, 
        
        address : { fontWeight: 400, fontSize: 10},
        
        theader : {marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theader2 : { flex:2, borderRightWidth:0, borderBottomWidth:1},

        tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

        total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},

        tbody2:{ flex:2, borderRightWidth:1, }
        
    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.reportTitle}>Inspire GmbH</Text>
            </View>
        </View>
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Quittung </Text>
                    <Text style={styles.invoiceNumber}>Beleg Nummer: {newTicketData.receiptId} </Text>
                    <Text style={styles.invoiceNumber}>Event: Lounges 2024 Karlsruhe </Text>
                    <Text style={styles.invoiceNumber}>Datum: {(new Date()).toLocaleDateString("de-DE")} </Text>
                </View>
                <View style={{alignItems: "flex-end"}}>
                    <Text style={styles.addressTitle}>Am Falltor 35</Text>
                    <Text style={styles.addressTitle}>64625 Bensheim</Text>
                    <Text style={styles.addressTitle}>+49 (0) 6221 3538072</Text>
                    <Text style={styles.addressTitle}>info@inspire-eventmanagement.de</Text>
                    <Text style={styles.addressTitle}>cleanroom-processes.de</Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{maxWidth : 200}}>
                    {/* <Text style={styles.addressTitle}>Bill to </Text> */}
                    <Text style={styles.address}>{newTicketData.personalAddress.firstName} {newTicketData.personalAddress.lastName}</Text>
                    <Text style={styles.address}>{newTicketData.personalAddress.company}</Text>
                    <Text style={styles.address}>{newTicketData.personalAddress.street} {newTicketData.personalAddress.streetNumber}</Text>
                    <Text style={styles.address}>{newTicketData.personalAddress.zip} {newTicketData.personalAddress.city}</Text>
                    <Text style={styles.address}>{newTicketData.personalAddress.countryCode}</Text>
                </View>
                
            </View>
        </View>
    );


    const TableHead = () => (
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Produkt</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Menge</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Preis</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Gesamt</Text>   
            </View>
        </View>
    );


    const TableBody = () => {
      const price = newTicketData?.invitationCode? 50 : 100;
      const qty = newTicketData?.eventDays?.length || 0;
      return (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={[styles.tbody, styles.tbody2]}>
                <Text >Tagesticket {newTicketData?.invitationCode ? "mit" : "ohne"} Einladungscode</Text>
                <Text >Tage: {newTicketData?.eventDays.map(day => new Date(day).toLocaleDateString("de-DE")).join(", ")}</Text>
            </View>
            <View style={styles.tbody}>
                <Text>{qty}</Text>   
            </View>
            <View style={styles.tbody}>
                <Text>{price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })} </Text>   
            </View>
            <View style={[styles.tbody, {alignItems: "flex-end"}]}>
                <Text>{(price * qty).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Text>   
            </View>
        </View>
      )
    };

    const TableTotal = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={styles.total}> 
                <Text></Text>
            </View>
            <View style={styles.total}>
                <Text> </Text>
            </View>
            <View style={[styles.tbody, {alignItems: "flex-end"}]}>
              <Text>Zwischensumme Netto</Text>
              <Text>zzgl. 19% MwSt.</Text>
              <Text>Total</Text>
            </View>
            <View style={[styles.tbody, {alignItems: "flex-end"}]}>
                <Text>
                    {(newTicketData?.eventDays?.length ? (newTicketData?.invitationCode ? 50 : 100) * newTicketData?.eventDays?.length / 1.19 : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                </Text>
                <Text>
                    {(newTicketData?.eventDays?.length ? (newTicketData?.invitationCode ? 50 : 100) * newTicketData?.eventDays?.length / 1.19 * 0.19 : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                </Text>
                <Text>
                    {(newTicketData?.eventDays?.length ? (newTicketData?.invitationCode ? 50 : 100) * newTicketData?.eventDays?.length : 0).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                </Text>
            </View>
        </View>
    );

    const TableFooter = () => (
      // Text "Betrag dankend erhalten"
      <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
         <Text >Betrag dankend erhalten</Text>
         
      </View>
    );

    const QRCodeElement = () => (
      <View style={{paddingTop: "20mm"}}>
            {qrCode &&<Image style={{width: "40mm", marginLeft: "70mm"}} src={qrCode} />}
            </View>
    );

    const DocumentFooter = () => (
      // Footer mit 3 spalten, fixed to bottom

      <View style={{ width:'100%', flexDirection :'row', top: 170}}>
        <View style={{ width:'33%', flexDirection :'column', fontSize : 9}}>
          <Text>Kontakt</Text>
          <Text>+49 (0) 6221 3538072</Text>
          <Text>info@inspire-eventmanagement.de</Text>
          <Text>cleanroom-processes.de</Text>
        </View>
        <View style={{ width:'33%', flexDirection :'column', fontSize : 9}}>
          <Text>Bankverbindung</Text>
          <Text>Inhaber: Inspire GmbH</Text>
          <Text>IBAN: DE16 6725 0020 0009 0727 13</Text>
          <Text>BIC/SWIFT: SOLADES1HDB</Text>
          <Text>Sparkasse Heidelberg</Text>
        </View>
        <View style={{ width:'33%', flexDirection :'column', fontSize : 9}}>
          <Text>Rechtliche Informationen</Text>
          <Text>Inhaber: Harald Martin</Text>
          <Text>UstId: DE143454505</Text>
          <Text>Amtsgericht Darmstadt HRB 85724</Text>
        </View>
      </View>
    );



    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/>
                <TableHead/>
                <TableBody/>
                <TableTotal/>
                <TableFooter/>
                <QRCodeElement/>
                <DocumentFooter/>
            </Page>
        </Document>
          
    )
}

  const generatePDFContentReceipt = async (ticketData) => {
    const InvoiceData = await Invoice();
    setPdfContentReceipt(InvoiceData);
    return Invoice;
  }

  useEffect(() => {
    if(Object.keys(newTicketData).length > 0) {
      generatePDFContentReceipt(newTicketData);
    }
  }, [newTicketData]);

  const getNextDocumentId = async () => {
    try {
      const data = await client.graphql({
        query: /* GraphQL */ `
        mutation GetNextSystemNumber($documentType: String) {
          getNextSystemNumber(documentType: $documentType) {
            nextDocumentId
            __typename
          }
        }
      `,
      });
      console.log(data);
      setNewTicketData(old => ({...old, receiptId: data.data.getNextSystemNumber.nextDocumentId}));
      
    } catch (error) {
      console.error(error);
    }
  }


  return (
<>
<div className="d-flex flex-wrap align-items-center mb-3">
  <h3 className="me-3">
    {events
      .filter(event => event.id === selectedEventId)
      .map(event => getLocalizedText(event.name))}
  </h3>
  <MDBSelect
    label={t("Event")}
    onChange={event => setSelectedEventId(event.value)}
    value={selectedEventId}
    data={[
      { text: t("Select event"), value: null },
      ...events.map(event => ({
        text: `${getLocalizedText(event.name)}: ${event.startDate && isoToGermanDate(event.startDate)} - ${event.endDate && isoToGermanDate(event.endDate)}`,
        value: event.id
      }))
    ]}
  />
</div>
{selectedEventId && (
<>

<MDBTabs className='mb-3'>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('print')} active={basicActive === 'print'}>
      {t("Print Tickets")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('search')} active={basicActive === 'search'}>
      {t("Search Tickets")}
    </MDBTabsLink>
  </MDBTabsItem>
  <MDBTabsItem>
    <MDBTabsLink onClick={() => handleBasicClick('book')} active={basicActive === 'book'}>
      {t("Book tickets")}
    </MDBTabsLink>
  </MDBTabsItem>
</MDBTabs>

<MDBTabsContent>
<MDBTabsPane open={basicActive === 'print'}>
  <MDBRow>
    <MDBCol md="6" xl="8">
      <MDBCard>
        <MDBCardBody className="px-xl-5">
          <MDBRow>
            <MDBCol size="10">
              <MDBInput label="Ticket ID" id="ticketId" type="text" value={ticketId} onChange={(e) => setTicketId(e.target.value)} onKeyDown={
              (e) => {
                if (e.key === "Enter") {
                  getTicketData();
                }
              }} />
              {ticketIdError && <div className="text-danger">{ticketIdError}</div>}
            </MDBCol>
            <MDBCol size="2">
              <MDBCheckbox label={t("Auto print")} checked={autoPrint} onChange={(e) => setAutoPrint(e.target.checked)} />
              </MDBCol>
          </MDBRow>
        
        <hr className="my-4" />
        <h6 className="mt-3">{t("Personal information")}</h6>
        <MDBRow>
          <MDBCol md="2">
            <MDBInput label={t("Title")} id="title" type="text" value={ticketData.title} onChange={(e) => setTicketData({...ticketData, title: e.target.value})} />
          </MDBCol>
          <MDBCol md="5">
            <MDBInput label={t("First name")} id="firstName" type="text" value={ticketData.firstName} onChange={(e) => setTicketData({...ticketData, firstName: e.target.value})} />
          </MDBCol>
          <MDBCol md="5">
            <MDBInput label={t("Last name")} id="lastName" type="text" value={ticketData.lastName} onChange={(e) => setTicketData({...ticketData, lastName: e.target.value})} />
          </MDBCol>
        </MDBRow>
        <MDBInput className="mt-3" label={t("Company")} id="company" type="text" value={ticketData.company} onChange={(e) => setTicketData({...ticketData, company: e.target.value})} />

        <h6 className="mt-3">{t("Visiting days")}</h6>
        {selectedEventId && getEventDays(events.find(event => event.id === selectedEventId)).map((day, index) => (
          <MDBCheckbox key={index} label={isoToGermanDate(day)} checked={ticketData?.eventDays?.includes(day)} onChange={(e) => setTicketData({...ticketData, eventDays: e.target.checked ? [...(ticketData.eventDays || []), day] : ticketData.eventDays.filter(d => d !== day)})} />
        ))}
        {/* <MDBCheckbox label="23.04. (Dienstag)" checked={ticketData?.eventDays?.includes("2024-04-23")} onChange={(e) => setTicketData({...ticketData, eventDays: e.target.checked ? [...(ticketData.eventDays || []), "2024-04-23"] : ticketData.eventDays.filter(day => day !== "2024-04-23")})} />
        <MDBCheckbox label="24.04. (Mittwoch)" checked={ticketData?.eventDays?.includes("2024-04-24")} onChange={(e) => setTicketData({...ticketData, eventDays: e.target.checked ? [...(ticketData.eventDays || []), "2024-04-24"] : ticketData.eventDays.filter(day => day !== "2024-04-24")})} />
        <MDBCheckbox label="25.04. (Donnerstag)" checked={ticketData?.eventDays?.includes("2024-04-25")} onChange={(e) => setTicketData({...ticketData, eventDays: e.target.checked ? [...(ticketData.eventDays || []), "2024-04-25"] : ticketData.eventDays.filter(day => day !== "2024-04-25")})} /> */}
        <h6 className="mt-3">{t("QR-Code Contact Details")}</h6>
        <MDBInput label={t("Email")} id="email" type="text" value={ticketData.email} onChange={(e) => setTicketData({...ticketData, email: e.target.value})} />
        <MDBInput className="mt-3" label={t("Phone")} id="phone" type="text" value={ticketData.phone} onChange={(e) => setTicketData({...ticketData, phone: e.target.value})} />
      
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
    <MDBCol md="6"  xl="4" className="text-center">
    
      <MDBBtn className="mb-3" onClick={() => {
        printTicket(pdfContent)
        }}>{t("Print")}</MDBBtn>
    
  <PDFViewer width="100%" height="600px">
    {pdfContent}
    </PDFViewer>
    <MDBTypography className={ticketData.visitorType === "staff" ? "fw-bold text-secondary display-6" : "fw-bold"}>
      {ticketData.visitorType === "staff" ? t("Exhibitor") : t("Visitor")}
    </MDBTypography>
    </MDBCol>
  </MDBRow>
  <div className="fixed-bottom w-25 mx-auto">
    <MDBInput label={t("Scan Device")} id="scanDevice" type="text" value={scanDevice} onChange={(e) => setScanDevice(e.target.value)} />
  </div>
</MDBTabsPane>
<MDBTabsPane open={basicActive === 'search'}>
       <MDBCard className="mb-3">
    <MDBCardBody>
    <MDBInput label="Search" id="searchString" type="text" value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyDown={
          (e) => {
            if (e.key === "Enter") {
              searchTicket(searchString);
            }
          }} />
    </MDBCardBody>
       </MDBCard>
  
  <MDBTable>
    <thead>
      <tr>
        <th>{t("First name")}</th>
        <th>{t("Last name")}</th>
        <th>{t("Company")}</th>
        <th>{t("Action")}</th>
      </tr>
    </thead>
    <tbody>
      {searchedTickets.map((ticket, index) => {
        return (
          <tr key={index}>
            <td>{ticket.firstName}</td>
            <td>{ticket.lastName}</td>
            <td>{ticket.companyName}</td>
            <td><MDBBtn onClick={() => {
              setTicketData({
                title: ticket.personalAddress.title,
                firstName: ticket.firstName,
                lastName: ticket.lastName,
                company: ticket.companyName,
                eventDays: ticket.eventDays,
                email: ticket.user?.email,
                phone: ticket.user?.contact?.phone,
                visitorType: ticket.visitorType,
                
              });
             setBasicActive('print');
            }}>{t("Select")}</MDBBtn></td>
          </tr>
        )
      })}
    </tbody>
  </MDBTable>
          
</MDBTabsPane>
<MDBTabsPane open={basicActive === 'book'}>
 {newTicketData.id ?
  <>
  <MDBRow>
    <MDBCol>
    <MDBCard>
    <MDBCardBody>
      <MDBCardTitle>{t("Ticket booked")}</MDBCardTitle>
      <MDBCardText>{t("Ticket ID")}: {newTicketData.id}</MDBCardText>
      <MDBCardText>{t("Invitation code")}: {newTicketData.invitationCode}</MDBCardText>
      <MDBCardText>{t("First name")}: {newTicketData.personalAddress?.firstName}</MDBCardText>
      <MDBCardText>{t("Last name")}: {newTicketData.personalAddress?.lastName}</MDBCardText>
      <MDBCardText>{t("Company")}: {newTicketData.personalAddress?.company}</MDBCardText>
    </MDBCardBody>
  </MDBCard>
  <MDBCol className="text-center mt-3">
  <MDBBtn onClick={() => resetBookTicket()}>{t("New Ticket")}</MDBBtn>
  </MDBCol>
  
  
    </MDBCol>
    <MDBCol className="text-center">
    <MDBBtn className="mb-3" onClick={() => {
        printTicket(pdfContentReceipt)
        }}>{t("Print")}</MDBBtn>
    {/* <MDBBtn className="mb-3" onClick={() => {
      getNextDocumentId();
    }}>{t("Generate Receipt")}</MDBBtn> */}
    
  <PDFViewer width="100%" height="600px">
    {pdfContentReceipt}
    </PDFViewer>
    </MDBCol>
  </MDBRow>
  
  </>
  :
  <>
   <MDBCard>
    <MDBCardBody>
      <MDBCardTitle>{t("Book ticket")}</MDBCardTitle>
       <MDBRow>
          <MDBCol md="6">
            <MDBInput label={t("First name")} id="firstName" type="text" value={bookTicketData.firstName} onChange={(e) => setBookTicketData({...bookTicketData, firstName: e.target.value})} />
          </MDBCol>
          <MDBCol md="6">
            <MDBInput label={t("Last name")} id="lastName" type="text" value={bookTicketData.lastName} onChange={(e) => setBookTicketData({...bookTicketData, lastName: e.target.value})} />
          </MDBCol>
       </MDBRow>
        <MDBRow className="mt-3">
            <MDBCol md="12">
              <MDBInput label={t("Company")} id="company" type="text" value={bookTicketData.company} onChange={(e) => setBookTicketData({...bookTicketData, company: e.target.value})} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
            <MDBCol md="8">
              <MDBInput label={t("Street")} id="street" type="text" value={bookTicketData.street} onChange={(e) => setBookTicketData({...bookTicketData, street: e.target.value})} />
            </MDBCol>
            <MDBCol md="4">
              <MDBInput label={t("Street number")} id="streetNumber" type="text" value={bookTicketData.streetNumber} onChange={(e) => setBookTicketData({...bookTicketData, streetNumber: e.target.value})} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
            <MDBCol md="6">
              <MDBInput label={t("Zip")} id="zip" type="text" value={bookTicketData.zip} onChange={(e) => setBookTicketData({...bookTicketData, zip: e.target.value})} />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput label={t("City")} id="city" type="text" value={bookTicketData.city} onChange={(e) => setBookTicketData({...bookTicketData, city: e.target.value})} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mt-3">
            <MDBCol md="12">
              <MDBInput label={t("Country")} id="country" type="text" value={bookTicketData.country} onChange={(e) => setBookTicketData({...bookTicketData, country: e.target.value})} />
            </MDBCol>
        </MDBRow>
        <h6 className="mt-3">{t("Visiting days")}</h6>
        {selectedEventId && getEventDays(events.find(event => event.id === selectedEventId)).map((day, index) => (
          <MDBCheckbox key={index} label={isoToGermanDate(day)} checked={bookTicketData?.eventDays?.includes(day)} onChange={(e) => setBookTicketData({...bookTicketData, eventDays: e.target.checked ? [...bookTicketData.eventDays, day] : bookTicketData.eventDays.filter(d => d !== day)})} />
        ))}
        {/* <MDBCheckbox label="23.04. (Dienstag)" checked={bookTicketData?.eventDays?.includes("2024-04-23")} onChange={(e) => setBookTicketData({...bookTicketData, eventDays: e.target.checked ? [...bookTicketData.eventDays, "2024-04-23"] : bookTicketData.eventDays.filter(day => day !== "2024-04-23")})} />
        <MDBCheckbox label="24.04. (Mittwoch)" checked={bookTicketData?.eventDays?.includes("2024-04-24")} onChange={(e) => setBookTicketData({...bookTicketData, eventDays: e.target.checked ? [...bookTicketData.eventDays, "2024-04-24"] : bookTicketData.eventDays.filter(day => day !== "2024-04-24")})} />
        <MDBCheckbox label="25.04. (Donnerstag)" checked={bookTicketData?.eventDays?.includes("2024-04-25")} onChange={(e) => setBookTicketData({...bookTicketData, eventDays: e.target.checked ? [...bookTicketData.eventDays, "2024-04-25"] : bookTicketData.eventDays.filter(day => day !== "2024-04-25")})} /> */}
        <h6 className="mt-3">{t("Invitation Code")}</h6>
        <MDBInput label={t("Invitation Code")} id="invitationCode" type="text" value={bookTicketData.invitationCode} onChange={(e) => setBookTicketData({...bookTicketData, invitationCode: e.target.value})} />
        <MDBBtn className="mt-3" onClick={() => {
          handleBookTicket()
        }}>{t("Book")}</MDBBtn>
    </MDBCardBody>
  </MDBCard>
  </>
  }
</MDBTabsPane>
</MDBTabsContent>

</>
)}
</>
    
  )
}
