import React, { useState, useEffect, useContext } from 'react';
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, 
         MDBModalTitle, MDBModalBody, MDBModalFooter, MDBCard, MDBCardBody, 
         MDBCardTitle, MDBCardText, MDBCol, MDBSpinner, 
         MDBCardImage,
         MDBRow} from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";
import { UserContext } from "../../App";

const client = generateClient();

const EmploymentRequests = () => {
  const { t } = useTranslation();
  const { user,  acceptEmploymentRequest} = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (user?.id) {
      fetchEmploymentRequests();
    }
  }, [user]);

  const fetchEmploymentRequests = async () => {
    setLoading(true);
    let allRequests = [];
    let currentToken = null;
  
    try {
      do {
        const response = await client.graphql({
          query: /* GraphQL */ `
            query ListEmploymentRequests(
              $filter: ModelEmploymentRequestFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listEmploymentRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  status
                  role
                  initiator
                  company {
                    id
                    name
                    image {
                        main {
                            fileName
                        }
                    }
                  }
                  companyEmploymentRequestsId
                  temporaryPassword
                  newCognitoUserCreated
                }
                nextToken
              }
            }
          `,
          variables: {
            filter: {
              userEmploymentRequestsId: { eq: user.id },
              status: { eq: "pending" }
            },
            limit: 1000,
            nextToken: currentToken
          }
        });
  
        const newRequests = response.data.listEmploymentRequests.items;
        allRequests = [...allRequests, ...newRequests];
        currentToken = response.data.listEmploymentRequests.nextToken;
  
      } while (currentToken);
  
      setRequests(allRequests);
    } catch (error) {
      console.error('Error fetching employment requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestAction = async (action) => {
    setProcessing(true);
    try {
      await client.graphql({
        query: `
          mutation UpdateEmploymentRequest(
            $input: UpdateEmploymentRequestInput!
          ) {
            updateEmploymentRequest(input: $input) {
              id
              status
            }
          }
        `,
        variables: {
          input: {
            id: selectedRequest.id,
            status: action
          }
        }
      });

      // Update local state
      setRequests(requests.filter(req => req.id !== selectedRequest.id));
      if(action === "accepted") {
        acceptEmploymentRequest(selectedRequest.companyEmploymentRequestsId);
        }
      setShowModal(false);
      setSelectedRequest(null);
    } catch (error) {
      console.error('Error updating employment request:', error);
    } finally {
      setProcessing(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center mt-4">
        <MDBSpinner />
      </div>
    );
  }

  if (requests.length === 0) {
    return null;
  }

  return (
    <MDBRow>
      <h5>{t("Employment Requests")}</h5>
      {requests.map((request) => (
        <MDBCol key={request.id} md="4" xl="3" className="mb-4">
        <MDBCard key={request.id} className="mb-3">
          <MDBCardBody>
            <MDBCardImage
                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + request.company.image?.main?.fileName}
                alt={request.company.name}
                style={{maxHeight: '200px', objectFit: 'contain' }}
                position="top" />
            <MDBCardTitle>{request.company.name}</MDBCardTitle>
            <MDBCardText>
              {t("Role")}: {request.role || t("Employee")}
            </MDBCardText>
            <MDBBtn 
              color="primary" 
              onClick={() => {
                setSelectedRequest(request);
                setShowModal(true);
              }}
            >
              {t("View Request")}
            </MDBBtn>
          </MDBCardBody>
        </MDBCard>
        </MDBCol>
      ))}

      <MDBModal open={showModal} onClose={() => setShowModal(false)}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {t("Employment Request from")} {selectedRequest?.company.name}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>{t("You have been invited to join")} {selectedRequest?.company.name} {t("as")} {selectedRequest?.role || t("Employee")}.</p>
              <p>{t("Would you like to accept or reject this invitation?")}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn 
                color="danger"
                outline
                onClick={() => setShowModal(false)}
                disabled={processing}
              >
                {t("Close")}
              </MDBBtn>
              <MDBBtn 
                color="primary"
                outline
                onClick={() => handleRequestAction("rejected")}
                disabled={processing}
              >
                {t("Reject")}
              </MDBBtn>
              <MDBBtn 
                color="primary" 
                onClick={() => handleRequestAction("accepted")}
                disabled={processing}
              >
                {processing ? <MDBSpinner size="sm" /> : t("Accept")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBRow>
  );
};

export default EmploymentRequests;