import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { 
    MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
    MDBTableBody, MDBTableHead, MDBIcon, MDBCollapse
} from "mdb-react-ui-kit";
import { UserContext } from "../../App";
import { isoToGermanDate } from "../../utils/dateTools";
import { transformMoney } from "../../utils/money";

const client = generateClient();

export default function CompanyBookings() {
    const { user } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const { companyid } = useParams();
    
    const [orders, setOrders] = useState([]);
    const [openOrders, setOpenOrders] = useState([]);

    useEffect(() => {
        if (companyid) getOrders(companyid);
    }, [companyid]);

    const getOrders = async (companyId) => {
        try {
            const data = await client.graphql({
                query: `
                query SearchOrders($filter: SearchableOrderFilterInput) {
                    searchOrders(filter: $filter) {
                        items {
                            id
                            orderNumber
                            createdAt
                            paymentStatus
                            status
                            financial {
                                invoiceAmount
                                invoiceAmountNet
                                invoiceFile {
                                    s3Path
                                }
                            }
                            lineItems {
                                items {
                                    id
                                    qty
                                    price
                                    product {
                                        id
                                        sku
                                        name {
                                            language
                                            text
                                        }
                                        icon
                                        type
                                    }
                                    bookedBooth {
                                        id
                                        boothNumber
                                        event {
                                            startDate
                                            endDate
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                `,
                variables: { filter: { companyOrdersId: { eq: companyId } } },
            });
            setOrders(data.data.searchOrders.items.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const toggleOrderDetails = (orderId) => {
        setOpenOrders(prevOpenOrders => 
            prevOpenOrders.includes(orderId)
                ? prevOpenOrders.filter(id => id !== orderId)
                : [...prevOpenOrders, orderId]
        );
    };

    return (
        <>
            <h2>{t("Bookings")}</h2>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle>
                        <h3>{t("Your Orders")}</h3>
                    </MDBCardTitle>
                    <MDBTable align="middle">
                        <MDBTableHead>
                            <tr>
                                <th>{t("Booking")}</th>
                                <th>{t("Total Amount")}</th>
                                <th>{t("Status")}</th>
                                <th>{t("Actions")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {orders.map((order) => (
                                <React.Fragment key={order.id}>
                                    <tr onClick={() => toggleOrderDetails(order.id)} style={{cursor: 'pointer'}}>
                                        <td>
                                            {order.orderNumber}
                                            <br />
                                            <small className="text-muted">
                                            {new Date(order.createdAt).toLocaleDateString("de-DE", {dateStyle: "medium"})} {new Date(order.createdAt).toLocaleTimeString("de-DE", {timeStyle: "short"})}
                                            </small>
                                        </td>
                                        <td>
                                            <span>{transformMoney(order.financial.invoiceAmount)}</span>
                                            <br />
                                            <span className="text-muted">{transformMoney(order.financial.invoiceAmountNet)}</span>
                                        </td>
                                        <td>
                                            {order.paymentStatus === "paid" ? (
                                                <div className="text-success">
                                                    <MDBIcon icon='check' /> {t("Paid")}
                                                </div>
                                            ) : (
                                                <div className="text-danger">{t(order.paymentStatus)}</div>
                                            )}
                                        </td>
                                        <td>
                                            {order.financial?.invoiceFile?.s3Path && (
                                                <MDBBtn color="link" size="sm" href={`https://iec-cp-public.s3.amazonaws.com/${order.financial.invoiceFile.s3Path}`} target="_blank">
                                                    {t("Download Invoice")}
                                                </MDBBtn>
                                            )}
                                           {order.lineItems?.items.map((item, index) => {
                                                if (item.product.type === "event_booth" && item.bookedBooth) {
                                                    const isEventEnded = new Date(item.bookedBooth.event?.endDate) < new Date();
                                                    const boothName = item.product.name.find(n => n.language === i18n.language)?.text || item.product.name[0]?.text;
                                                    
                                                    return (
                                                        <Link key={index} to={`/mycompany/${companyid}/edit-booth/${item.bookedBooth?.id}`}>
                                                            <MDBBtn color="primary" className="mt-2" outline size="sm">
                                                                {isEventEnded ? 
                                                                    `${t("View booth")}: ${boothName}` :
                                                                    `${t("Configure booth")}: ${boothName}`
                                                                }
                                                            </MDBBtn>
                                                        </Link>
                                                    );
                                                } else if (item.product.type === "event_booth_partner" && item.bookedBooth) {
                                                    const isEventEnded = new Date(item.bookedBooth.event?.endDate) < new Date();
                                                    const boothName = item.product.name.find(n => n.language === i18n.language)?.text || item.product.name[0]?.text;
                                                    
                                                    return (
                                                        <Link key={index} to={`/mycompany/${companyid}/edit-booth/${item.bookedBooth?.id}`}>
                                                            <MDBBtn color="primary" className="mt-2" outline size="sm">
                                                                {isEventEnded ? 
                                                                    `${t("View")}: ${boothName}` :
                                                                    `${t("Book Marketing")}: ${boothName}`
                                                                }
                                                            </MDBBtn>
                                                        </Link>
                                                    );
                                                } 
                                                return null;
                                            })}

                                            {order.lineItems?.items.map((item, index) => {
                                                if (item.product.type === "event_space" && item.bookedBooth) {
                                                    const isEventEnded = new Date(item.bookedBooth.event?.endDate) < new Date();
                                                    const boothName = item.product.name.find(n => n.language === i18n.language)?.text || item.product.name[0]?.text;
                                                    
                                                    return (
                                                        <>
                                                        <br />
                                                        <Link key={index} to={`/mycompany/${companyid}/edit-booth/${item.bookedBooth?.id}`}>
                                                            <MDBBtn color="primary" className="mt-2" outline size="sm">
                                                                {isEventEnded ? 
                                                                    `${t("View booth additional space")}: ${boothName}` :
                                                                    `${t("Configure booth additional space")}: ${boothName}`
                                                                }
                                                            </MDBBtn>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </td>
                                    </tr>
                                    <tr style={{display: openOrders.includes(order.id) ? "" : "none"}}>
                                        <td colSpan="4">
                                            <MDBCardTitle>{t("Order Details")}</MDBCardTitle>
                                            <MDBTable>
                                                <MDBTableHead>
                                                    <tr>
                                                        <th>{t("SKU")}</th>
                                                        <th>{t("Product")}</th>
                                                        <th>{t("Quantity")}</th>
                                                        <th>{t("Price")}</th>
                                                    </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                    {order.lineItems.items.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{item.product.sku}</td>
                                                            <td>
                                                                {item.product.name.find(n => n.language === i18n.language)?.text || item.product.name[0]?.text}
                                                                {item.bookedBooth && (
                                                                    <div><small>{t("Booth Number")}: {item.bookedBooth.boothNumber}</small></div>
                                                                )}
                                                            </td>
                                                            <td>{item.qty}</td>
                                                            <td>{transformMoney(item.price)}</td>
                                                        </tr>
                                                    ))}
                                                </MDBTableBody>
                                            </MDBTable>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBCardBody>
            </MDBCard>
        </>
    );
}