// Lecture Type
// type Lecture
//   @model
//   @auth(rules: [
//     { allow: private, operations: [read] },
//     { allow: groups, groups: ["Admin, Moderator"], operations: [create, read, update, delete] }])
// {
//   id: ID!
//   startTime: AWSDateTime!
//   endTime: AWSDateTime!
//   event: Event @belongsTo
//   company: Company @hasOne
//   speakers: [Employee] @hasMany
//   session: String
//   room: String
//   title: [MultiLangString!]!
//   subjects: [MultiLangStringList]
//   content: MultiLangString
//   presentationLanguage: String
//   image: Image
//   pdfDe: File
//   pdfEn: File
// }

import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isoToGermanDate } from "../../../utils/dateTools";
import moment from 'moment-timezone';
import { useIndustry } from "../../../utils/industryContextHook";
const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();
    const { currentIndustry } = useIndustry();

    const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];

    const [lectures, setLectures] = useState([]);

    const getLectures = async () => {
        try {
            const lectures = await client.graphql({
                variables: {
                    limit: 10000,
                    filter: {
                        companyLecturesId: {
                          eq: companyid
                        },
                        createdAt: {
                            gt: "2023-10-01T00:00:00.000Z"
                        },
                        type: {
                            matchPhrasePrefix: "promotion"
                        }            
                    }
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      id
                      title {
                        language
                        text
                      }
                      topics {
                        items {
                          topic {
                            id
                            name {
                              language
                              text
                            }
                          }
                        }
                    }  
                      speakers {
                        items {
                            employee {
                                image {
                                  fileName
                                  alt
                                }
                              }
                        }
                      }
                      event {
                        startDate
                        actionEditEndDate
                        name {
                          language
                          text
                        }
                        id
                      }
                      event {
                        id
                        name {
                          language
                          text
                        }
                      }
                      type
                      session
                      room
                      startTime
                      endTime
                      dateTimes
                      companyBooth
                      presentationLanguage
                      createdAt
                      updatedAt
                      companyLecturesId
                      eventLecturesId
                      lectureMainSpeakerId
                      __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            console.log(lectures.data.searchLectures.items)
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})));
        } catch (error) {
            console.log("error on fetching lectures", error);
            if(error.data?.searchLectures?.items) setLectures(error.data.searchLectures.items);
        }
    };

    const [events, setEvents] = useState([]);
    const getCompanyAttendingEvents = async (companyId) => {
        // Get company attending events by getting listBookedBooths with filter companyBookedBoothsId
        try {
            let companyBookedBooths = []; let nextToken = null;
            do {
                const response = await client.graphql({
                    query: /* GraphQL */ `query ListBookedBooths(
                        $filter: ModelBookedBoothFilterInput
                        $limit: Int
                        $nextToken: String
                    ) {
                        listBookedBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
                            items {
                                id
                                event {
                                    id
                                }
                            }
                            nextToken
                        }
                    }`,
                    variables: {
                        filter: {
                            companyBookedBoothsId: {
                                eq: companyId
                            }
                        },
                        limit: 100, // Adjust the limit as needed
                        nextToken: nextToken
                    }
                });

                companyBookedBooths = companyBookedBooths.concat(response.data.listBookedBooths.items);
                nextToken = response.data.listBookedBooths.nextToken;
            } while (nextToken);
            return companyBookedBooths.map(item => item.event.id);
        } catch (error) {
            console.log("error on fetching company attending events", error);
        }
    }
    const getEvents = async () => {
        try {
            // Get events by getting industry events
            const companyAttendingEventIds = await getCompanyAttendingEvents(companyid);
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListIndustryEvents(
                    $filter: ModelIndustryEventsFilterInput
                    $limit: Int
                    $nextToken: String
                    ) {
                    listIndustryEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                        event {
                        id
                        startDate
                        lectureRegistrationStartDate
                        lectureRegistrationEndDate
                        status
                        name {
                            language
                            text
                        }
                        }
                    }
                    nextToken
                    }
                }
                `,
                variables: {
                    filter: {
                       and:{
                        industryId: {
                            eq: currentIndustry?.id
                        },
                        or: companyAttendingEventIds.map(id => ({
                            eventId: {
                                eq: id
                            }
                        }))
                       }
                    }
                }
            });
            // Filter events that are active and have startDate in the future
            setEvents(events.data.listIndustryEvents.items
                .filter(event => event.event.status === "active" && new Date(event.event.lectureRegistrationStartDate) <= new Date() && new Date(event.event.lectureRegistrationEndDate) >= new Date())
                .map(event => event.event));
        } catch (error) {
            console.log("error on fetching events", error);
        }
    }

    useEffect(() => {
        getLectures();
        getEvents();
    }, []);


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }

    const canEditLecture = (lecture) => {
        // Wenn der Vortrag einem Event zugeordnet ist und das Event bereits begonnen hat oder lectureEditEndDate des Events überschritten ist
        if ((lecture.event?.startDate && new Date(lecture.event.startDate) <= new Date()) || (lecture.event?.lectureEditEndDate && new Date(lecture.event.lectureEditEndDate) < new Date())) {
            return false;
        }
        return true;
    };
    
    const canDeleteLecture = (lecture) => {
        // Wenn der Vortrag bereits eine Startzeit hat, kann er nicht gelöscht werden
        if (lecture.startTime) {
            return false;
        }
        return true;
    };



  return (
<>

<MDBRow className="mb-3 mb-lg-5">
{events.length > 0 && events.map((event) => (
        <MDBCol size="12" lg="5" key={event.id}>
            <MDBCard className="mx-auto text-center" style={event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate) > new Date() ? {backgroundColor: "#c0c0c0"} : {}}>
                <MDBCardBody>
                    <MDBCardTitle>
                        <h2>{event.name.find(n => n.language === i18n.language)?.text || event.name[0].text}</h2>
                    </MDBCardTitle>
                    {event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate) <= new Date() &&
                    <h5>{t("Register Promotion for ")} {event.name.find(n => n.language === i18n.language)?.text || event.name[0].text} {t("now.")}</h5>
                    }
                    {event.lectureRegistrationStartDate && new Date(event.lectureRegistrationStartDate) > new Date() ?
                        <p>{t("Registration will start on")} {isoToGermanDate(event.lectureRegistrationStartDate)}</p>
                        :
                        <Link to={`/mycompany/${companyid}/register-action?eventId=${event.id}`}>
                            <MDBBtn>{t("Register Promotion")}</MDBBtn>
                        </Link>
                    }
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    ))}
</MDBRow>



<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>
            <h2>{t("Promotions")}</h2>
        </MDBCardTitle>
        <MDBTable>
            <MDBTableHead>
                <tr>
                <th>{t("Event")}</th>
                <th>{t("Type")}</th>
                    <th>{t("Title")}</th>
                    <th>{t("Speakers")}</th>
                    <th>{t("Date and Time")}</th>
                    <th>{t("Location")}</th>
                    <th>{t("Actions")}</th>

                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {lectures.length > 0 ? 
                    lectures.map((lecture) => (
                        <tr key={lecture.id}>
                        <td>{lecture.event?.name?.find(n => n.language === i18n.language)?.text || lecture.event?.name?.[0]?.text}</td>
                            <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                            <td>{t(lecture.type)}</td>
                            <td>{lecture.speakers?.items?.map(speaker => <>
                            {speaker?.employee?.image?.fileName ?
                            <img
                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                }}
                                alt={speaker.employee?.image.alt}
                            />
                            :
                            <MDBIcon
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    textAlign: "center",
                                    paddingTop: "12px",
                                    fontSize: "20px",
                                }}
                                fas
                                icon="user-alt"
                            />
                            }
                            </>)}</td>
                            <td>
                              {lecture.dateTimes && lecture.dateTimes.length > 0 ? 
                                lecture.dateTimes.map(dateTime => {
                                  // Konvertiere das ISO-Datum in die deutsche Zeitzone
                                  const dateInGermany = moment(dateTime).tz("Europe/Berlin");
                                  return (
                                    <>
                                      {isoToGermanDate(dateInGermany.format())} - {dateInGermany.format('HH:mm')} Uhr
                                      <br />
                                    </>
                                  );
                                })
                                : "noch nicht festgelegt"}
                            </td>
                            <td>{lecture.companyBooth || t("not yet determined")}</td>
                            
                            <td>
                            <MDBBtnGroup shadow="0">
                            {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                            {/* <Link to={`/mycompany/${companyid}/register-action/${lecture.id}`}>
                                <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn color='link' style={{color: "red"}} className="px-2" onClick={() => {setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal()}}>{t("Delete")}</MDBBtn> */}
                            {canEditLecture(lecture) && (
                            <Link to={`/mycompany/${companyid}/register-action/${lecture.id}`}>
                                <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                            </Link>)}
                            {canDeleteLecture(lecture) && (
                                <MDBBtn 
                                    color='link' 
                                    style={{color: "red"}} 
                                    className="px-2" 
                                    onClick={() => {
                                        setSelectedLectureRemove(lecture.id); 
                                        toggleDeleteLectureModal();
                                    }}
                                >
                                    {t("Delete")}
                                </MDBBtn>
                            )}
                            </MDBBtnGroup>
                            </td>
                        </tr>
                    ))
                :
                    <tr>
                        <td colSpan="7">
                            {t("No promotions found")}
                        </td>
                    </tr>
                }
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove promotion")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
            <p>{t("Are you sure you want to remove this promotion?")}</p>
            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
