import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
const client = generateClient();

export default function CancelInvitation(props) {
    const { t, i18n } = useTranslation();
    const { companyId, employee, isActive, onCancel, onDeletedSuccess } = props;

    const handleCancelInvitation = async () => {
        try {
            // First get the employment request ID
            const employmentRequestResponse = await client.graphql({
                query: /* GraphQL */ `
                query GetCompany($id: ID!, $filter: ModelEmploymentRequestFilterInput) {
                    getCompany(id: $id) {
                        employmentRequests(filter: $filter) {
                            items {
                                id
                            }
                        }
                    }
                }
                `,
                variables: { 
                    id: companyId,
                    filter: {
                        userEmploymentRequestsId: { eq: employee.user.id }
                    }
                }
            });

            const employmentRequest = employmentRequestResponse.data.getCompany.employmentRequests.items[0];

            if (employmentRequest) {
                // Delete the employment request
                await client.graphql({
                    query: /* GraphQL */ `
                    mutation DeleteEmploymentRequest($input: DeleteEmploymentRequestInput!) {
                        deleteEmploymentRequest(input: $input) {
                            id
                        }
                    }
                    `,
                    variables: {
                        input: {
                            id: employmentRequest.id
                        }
                    }
                });
            }

            // Delete the employee
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteEmployee($input: DeleteEmployeeInput!) {
                    deleteEmployee(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id: employee.id
                    }
                }
            });

            if (onDeletedSuccess) {
              onDeletedSuccess(employee.id);
            }
            
            if (onCancel) {
                onCancel();
            }

           
        } catch (error) {
            console.error("Error canceling invitation:", error);
        }
    };

    return (
        <div className="text-center">
            <p>
                {t("Are you sure you want to cancel this invitation?")}
            </p>
            {employee && 
                <div className='d-flex align-items-center'>
                    {employee?.image?.fileName ?
                        <img
                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+employee.image.fileName}
                            className="rounded-circle border"
                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                            alt={employee.image.alt}
                        />
                        :
                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                    }
                    <div className='ms-3'>
                        <p className='fw-bold mb-1'>{employee.user.firstName} {employee.user.middleName} {employee.user.lastName}</p>
                        <p className='text-muted mb-0'>{employee.user.email}</p>
                    </div>
                </div>
            }
            <MDBBtn 
                color="danger" 
                size="sm" 
                className="mt-4"
                onClick={handleCancelInvitation}
            >
                {t("Cancel Invitation")}
            </MDBBtn>
        </div>
    );
}