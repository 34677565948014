import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
MDBTableBody, MDBTableHead, MDBBtnGroup, MDBIcon, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalTitle, MDBCardText, MDBCardImage
 } from "mdb-react-ui-kit"
import { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { generateClient } from "aws-amplify/api"
import { UserContext } from "../../../App"
import { isoToGermanDate } from "../../../utils/dateTools"
import { deleteTicket } from "../../../graphql/mutations"
import ShowTicket from "../../../components-user/tickets/ShowTicket"

const client = generateClient()

export default function AllTickets() {
    const { user } = useContext(UserContext);
    const { t, i18n } = useTranslation()

    const [tickets, setTickets] = useState([])
    const [orders, setOrders] = useState([])

    const getOrdersAndTickets = async (userId) => {
        try {
        const data = await client.graphql({
            query: /* GraphQL */ `
            query GetUser($id: ID!) {
            getUser(id: $id) {
                id
                tickets {
                items {
                    id
                    createdByUserId
                    eventDays
                    status
                    paymentStatus
                    type
                    addons
                    notes
                    invitationCode
                    createdAt
                    updatedAt
                    event {
                        id
                        ticketCancellationDeadline
                        name {
                        language
                        text
                        }
                    }
                    personalAddress {
                        firstName
                        lastName
                    }
                    ticketPaymentId
                    ticketFile {
                        s3Path
                    }
                    __typename
                }
                nextToken
                __typename
                }
                orders {
                items {
                    createdAt
                    id
                    deliveryAddress {
                        firstName
                        lastName
                    }
                    billingAddress {
                        firstName
                        lastName
                    }
                    billingEmail
                    totalAmount
                    status
                    paymentStatus
                    financial {
                        invoiceFile {
                        s3Path
                        }
                    }
                    deliveryStatus
                    lineItems {
                        items {
                        product {
                            id
                        name {
                            language
                            text
                        }
                        price
                        image {
                            main {
                            fileName
                            }
                        }
                        icon
                        type
                    }
                    }
                        }
                    }
                }
                createdAt
                updatedAt
                owner
                __typename
            }
            }
        `,
            variables: { id: userId },
        })
        //console.log(data)
            setTickets(data.data.getUser.tickets.items)
            setOrders(data.data.getUser.orders.items)
        } catch (error) {
        console.error(error)
        }
    }


    useEffect(() => {   
        if(user.id) getOrdersAndTickets(user.id);
        
    }, [user])


    const handleDeleteTicket = async (id) => {
        try {
          const data = await client.graphql({
            query: deleteTicket,
            variables: { input: { id } },
            })
            console.log(data)
            setTickets(tickets.filter(ticket => ticket.id !== id))
        }
        catch (error) {
          console.error(error)
        }
    }

    const [viewTicketModal, setViewTicketModal] = useState(false);
    const toggleViewTicketModal = () => setViewTicketModal(!viewTicketModal);

    const [selectedTicket, setSelectedTicket] = useState(null);

    const [viewOrderModal, setViewOrderModal] = useState(false);
    const toggleViewOrderModal = () => setViewOrderModal(!viewOrderModal);

    const [selectedOrder, setSelectedOrder] = useState(null);

  return (
<>


<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>
            <h2>{t("Bookings & Tickets")}</h2>
        </MDBCardTitle>
        <MDBTable align="middle">
            <MDBTableHead>
                <tr>
                <th>{t("Type")}</th>
                    <th>{t("Details")}</th>
                    <th>{t("Booking date")}</th>
                    <th>{t("Payment status")}</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {tickets.map((ticket, index) => (
                    <tr key={index}>
                        <td className=""><MDBIcon fas icon="ticket" color="secondary" size="2x" className="me-3" />
                        <span style={{verticalAlign: "super"}}>{t("Ticket")} {ticket.event.name.find(name => name.language === i18n.language)?.text || ticket.event.name[0]?.text}</span>
                        </td>
                        <td>
                        {/* {ticket.type === "default" ?
                        t("Visitor Ticket") : t("Premium ticket")
                        } <br/> */}
                        {ticket.personalAddress?.firstName} {ticket.personalAddress?.lastName} <br/>
                        {ticket.eventDays.map((day, index) => (index !== 0 ? ", " : "" ) +isoToGermanDate(day))}
                        <div className="d-lg-none">
                            <MDBBtn color="primary" size="sm"
                            onClick={() => {
                                setSelectedTicket(ticket)
                                toggleViewTicketModal()
                            }}
                            >{t("View")}</MDBBtn>
                        </div>
                        </td>
                        <td>{isoToGermanDate(ticket.createdAt)}</td>
                        <td><div className="text-success"><MDBIcon icon='check' /> {t("Paid")}</div></td>
                        <td>
                            <MDBBtnGroup>
                                <MDBBtn color="primary" size="sm"
                                    onClick={() => {
                                        setSelectedTicket(ticket)
                                        toggleViewTicketModal()
                                    }}
                                    >{t("View")}</MDBBtn>
{/*                             
                                    <MDBBtn color="danger" size="sm"
                                    onClick={() => {
                                        if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                                            handleDeleteTicket(ticket.id)
                                        }
                                    }}
                                    >{t("Delete")}</MDBBtn> */}

                            </MDBBtnGroup>
                        </td>
                    </tr>
                ))
                }
                {orders.map((order, index) => (
                    <tr key={index}>
                        <td>
                        <MDBIcon fas icon={order.lineItems?.items?.[0]?.product?.icon} color="secondary" size="2x" className="me-3" />
                        <span style={{verticalAlign: "super"}}>{t(order.lineItems?.items?.[0]?.product?.type)}</span>
                        </td>
                        <td>
                        {order.lineItems?.items.map((product, index) => (
                            <div key={index}>{product.product.name.find(name => name.language === i18n.language)?.text || product.product.name[0]?.text}</div>
                        ))}
                        
                        </td>
                        <td>{isoToGermanDate(order.createdAt)}</td>
                        <td>{order.paymentStatus === "paid" ? <div className="text-success"><MDBIcon icon='check' /> {t("Paid")}</div> : <div className="text-danger"><MDBIcon icon='times' /> {t("Unpaid")}</div>}</td>
                        <td>
                            <MDBBtnGroup>
                                <MDBBtn color="primary" size="sm"
                                    onClick={() => {
                                        setSelectedOrder(order)
                                        toggleViewOrderModal()
                                    }}
                                    >{t("View")}</MDBBtn>
                            </MDBBtnGroup>
                        </td>
                    </tr>
                ))
                }
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>

<MDBModal open={viewTicketModal} onClose={() => setViewTicketModal(false)} position="top">
    <MDBModalDialog>
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Your ticket")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleViewTicketModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <ShowTicket ticket={selectedTicket} handleDeleteTicket={handleDeleteTicket} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={viewOrderModal} onClose={() => setViewOrderModal(false)} position="top">
    <MDBModalDialog>
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Your order")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleViewOrderModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <h4>{t("Order details")}</h4>
        <p>{t("Order date")}: {isoToGermanDate(selectedOrder?.createdAt)}</p>
        <h5>{t("Invoice")}</h5>
        {selectedOrder?.financial?.invoiceFile?.s3Path ? (
            <a href={"https://iec-cp-public.s3.amazonaws.com/" + selectedOrder?.financial?.invoiceFile?.s3Path} target="_blank" rel="noreferrer">{t("Download invoice")}</a>
        ) : (
            <p>{t("No invoice available yet")}</p>
        )}
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

</>
  )
}
