
import { MDBBtn, MDBCard, MDBCardBody, MDBCheckbox, MDBCol, MDBRow, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from "mdb-react-ui-kit"

import { useTranslation } from "react-i18next"
import { generateClient } from 'aws-amplify/api';
import { isoToGermanDate } from "../../../../utils/dateTools";
import { getLocalizedText } from "../../../../utils/localizedText";
import { useEffect, useState, useMemo } from "react";
import SideCart from "../../../order/checkout/SideCart";
import { lang } from "moment-timezone";


export default function Booking_Confirmation({user, event, params, taxRate, personalAddress, useProfileAddressForBilling, setOrderDiscounts, setUseProfileAddressForBilling, 
    billingAddress, handleBookTicket, bookingInProgress, handleClickPrev, handleClickNext, errors}) {
        const client = generateClient()
        const { t, i18n } = useTranslation()

      const [tocAccepted, setTocAccepted] = useState(false);
      const [showTacModal, setShowTacModal] = useState(false);
      const [showPrivacyModal, setShowPrivacyModal] = useState(false);

    // Discount code and handling
    const [discountCode, setDiscountCode] = useState("");
    const [appliedDiscounts, setAppliedDiscounts] = useState([]);
    const [discountErrorMessages, setDiscountErrorMessages] = useState([]);

    const applyDiscountCode = async () => {
      try {
        const response = await client.graphql({
          query: /* GraphQL */ `
            query DiscountCodesByCode(
            $code: String!
          ) {
            discountCodesByCode(
              code: $code
            ) {
              items {
                id
                code
                name {
                  language
                  text
                }
                description {
                  language
                  text
                }
                discountType
                discountAmount
                discountPercentage
                maxUsage
                usageCount
                startDate
                endDate
                status
                restrictedProductIds
                canBeCombined
              }
              nextToken
              __typename
            }
          }
        `,
          variables: {
            code: discountCode
          }
        });
        console.log("response", response);
        const discount = response.data.discountCodesByCode.items[0];
        if(discount) {
          // check if discount already applied
          if (appliedDiscounts.find(d => d.id === discount.id)) {
            setDiscountErrorMessages(["Discount code already applied"]);
            return;
          } else if(discount.startDate && new Date(discount.startDate) > new Date()) {
            setDiscountErrorMessages(["Discount code is not yet valid"]);
            return;
          } else if(discount.endDate && new Date(discount.endDate) < new Date()) {
            setDiscountErrorMessages(["Discount code has expired"]);
            return;
          } else if(discount.maxUsage && discount.usageCount >= discount.maxUsage) {
            setDiscountErrorMessages(["Discount code has reached maximum usage"]);
            return;
          } else {
            setDiscountErrorMessages([]);
            if(discount.canBeCombined) {
            setAppliedDiscounts([...appliedDiscounts, discount]);
            } else {
              setAppliedDiscounts([discount]);
            }
          }

      } else {
        setDiscountErrorMessages(["Discount code not found"]);
      }
      }
      catch (error) {
        console.error("Error applying discount code", error);
      }
    }

    const removeDiscountCode = (id) => {
      const updatedDiscounts = appliedDiscounts.filter(discount => discount.id !== id);
      setAppliedDiscounts(updatedDiscounts);
    }

    //Calculate discounts and update discount with a new field amount (if restrictedProductIds is set, only apply to those products)
    // useEffect(() => {
    //   const calculateDiscount = (discount) => {
    //     if(discount.discountType === "percentage") {
    //       return [event?.ticketProduct].reduce((acc, product) => {
    //         if(discount.restrictedProductIds && discount.restrictedProductIds.includes(product.id)) {
    //           return acc + (product.qty && product.qty > 1 ? product.price * product.qty : product.price) * discount.discountPercentage / 100;
    //         } else if(!discount.restrictedProductIds) {
    //           return acc + (product.qty && product.qty > 1 ? product.price * product.qty : product.price) * discount.discountPercentage / 100;
    //         } else {
    //           return acc;
    //         }
    //       }, 0);
    //     } else if (discount.discountType === "fixedAmount") {
    //       if(discount.restrictedProductIds) {
    //         return [event?.ticketProduct].reduce((acc, product) => {
    //           if(discount.restrictedProductIds.includes(product.id)) {
    //             return acc + discount.discountAmount;
    //           } else {
    //             return acc;
    //           }
    //         }, 0);
    //       } else {
    //         return discount.discountAmount;
    //       }
    //     }
    //   }
    //   const updatedDiscounts = appliedDiscounts.map(discount => {
    //     return {
    //       ...discount,
    //       amount: calculateDiscount(discount)
    //     }
    //   });
    //   setAppliedDiscounts(updatedDiscounts);
    // }, [event.ticketProduct, appliedDiscounts]);


    const discountsWithAmounts = useMemo(() => {
      if (!event?.ticketProduct) return appliedDiscounts; // or [] if you prefer
  
      // Helper function to calculate discount amount for one discount
      const calculateDiscount = (discount) => {
        // For a percentage discount
        if (discount.discountType === "percentage") {
          // In your original code, you worked with an array even though there's only one product.
          // Here, we can assume ticketProduct is the only product to consider.
          const product = event.ticketProduct;
          const productTotal =
            product.qty && product.qty > 1 ? product.price * product.qty : product.price;
          // Apply discount only if no restrictions exist or the product is in the restricted list.
          if (
            (discount.restrictedProductIds && discount.restrictedProductIds.includes(product.id)) ||
            !discount.restrictedProductIds
          ) {
            return productTotal * discount.discountPercentage / 100;
          }
          return 0;
        }
        // For a fixed amount discount
        else if (discount.discountType === "fixedAmount") {
          if (discount.restrictedProductIds) {
            return discount.restrictedProductIds.includes(event.ticketProduct.id)
              ? discount.discountAmount
              : 0;
          } else {
            return discount.discountAmount;
          }
        }
        return 0;
      };
  
      // Return a new array with the discount amounts calculated.
      return appliedDiscounts.map((discount) => ({
        ...discount,
        amount: calculateDiscount(discount),
      }));
    }, [appliedDiscounts, event.ticketProduct]);

    // Set discounts to order
    useEffect(() => {
      setOrderDiscounts(discountsWithAmounts);
    }, [discountsWithAmounts, setOrderDiscounts]);


  return (
    event &&
<>
<h4>{t("Confirmation")}</h4>

    <MDBRow>
        <MDBCol size="12" lg="6">
        <MDBCard className="mt-2 mt-lg-4 text-start">
            <MDBCardBody>
                <h5>{t("Personal information")}</h5>
                {personalAddress?.company}
                <br></br>
                {personalAddress?.title ? personalAddress.title + " " : ""}{personalAddress?.firstName} {personalAddress?.middleName} {personalAddress?.lastName}
                <br></br>
                {personalAddress?.street} {personalAddress?.streetNumber}
                <br></br>
                {personalAddress?.zip} {personalAddress?.city}
                <br></br>
                {personalAddress?.countryCode}
                <h5 className="mt-3">{t("Billing address")}</h5>
                {useProfileAddressForBilling ?
                    <>
                    {personalAddress?.company}
                    <br></br>
                    {personalAddress?.title ? personalAddress?.title + " " : ""}{personalAddress?.firstName} {personalAddress?.middleName} {personalAddress?.lastName}
                    <br></br>
                    {personalAddress?.street} {personalAddress?.streetNumber}
                    <br></br>
                    {personalAddress?.zip} {personalAddress?.city}
                    <br></br>
                    {personalAddress?.countryCode}
                    </>
                    :
                    <>
                    {billingAddress.company}
                    <br></br>
                    {billingAddress.firstName} {billingAddress.lastName}
                    <br></br>
                    {billingAddress.street} {billingAddress.streetNumber}
                    <br></br>
                    {billingAddress.zip} {billingAddress.city}
                    <br></br>
                    {billingAddress.countryCode}
                    </>
                }
                <h5 className="mt-3">{t("Payment method")}</h5>
                <p>{t("Invoice")}</p>
            </MDBCardBody>
        </MDBCard>
        </MDBCol>
        <MDBCol size="12" lg="6" className="text-start">
        {event &&
        <SideCart
            taxRate={taxRate}
            customerType={"consumer"}
            products={[{
                name: event.name.map(n => ({language: n.language, text: "Ticket " + n.text})),
                image: {main: {url: "https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.image.fileName}},
                price: event.ticketProduct?.price,
                shortDescription: [{text: params.selectedDays?.map((day, index) => {
                    return (
                        <span key={index}>{isoToGermanDate(day)}{index < params.selectedDays.length - 1 ? ", " : ""}</span>
                    )
                })}],
                qty: 1
            }]
            }
            showDiscountCode={true}
            discountCode={null}
            setDiscountCode={setDiscountCode}
            applyDiscountCode={applyDiscountCode}
            removeDiscountCode={removeDiscountCode}
            appliedDiscounts={discountsWithAmounts}
            discountErrorMessages={discountErrorMessages}
        />
        }
        </MDBCol>
        
    </MDBRow>

<MDBRow>
    <MDBCol size="12">
    <div className="mt-2 mt-lg-4 text-start mx-auto" style={{width: "fit-content"}}>
                        <MDBCheckbox label={
                            <>
                            {t("I accept the ")}
                            <span className="link-primary" onClick={(e) => {
                                setShowTacModal(true);
                            }}>{t("terms and conditions")}</span>
                            {t(" and the ")}
                            <span className="link-primary" onClick={(e) => {
                                setShowPrivacyModal(true);
                            }}>{t("privacy policy")}</span>
                            </>}
                            checked={tocAccepted}
                            onChange={e => setTocAccepted(e.target.checked)}
                        />
                    </div>
    </MDBCol>
    <MDBCol size="12" className="mx-auto" style={{maxWidth: "500px"}}>
    {bookingInProgress ?
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    :
        <MDBBtn disabled={!tocAccepted} color="success" className="mt-2 mt-lg-4" style={{width: "100%"}} onClick={() => handleBookTicket()}>{t("Book now")}</MDBBtn>
    }
    {errors?.length > 0 && (
        <div className="alert alert-danger mt-2 mt-lg-4">
            {errors.map((error, index) => {
                return (
                    <p key={index}>{error}</p>
                )
            })}
        </div>
    )}
    </MDBCol>
</MDBRow>

 {/* Terms and Conditions Modal */}
 <MDBModal open={showTacModal} onClose={() => setShowTacModal(false)}>
    <MDBModalDialog size="xl" className="text-start">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Terms and Conditions")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowTacModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(event?.ticketTAC) }} />
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

{/* Privacy Policy Modal */}
<MDBModal open={showPrivacyModal} onClose={() => setShowPrivacyModal(false)}>
    <MDBModalDialog size="xl" className="text-start">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Privacy Policy")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={() => setShowPrivacyModal(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <div dangerouslySetInnerHTML={{ __html: getLocalizedText(event?.ticketPrivacyPolicy) }} />
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
