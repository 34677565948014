import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBSelect, MDBBtn, MDBSpinner, MDBFile, MDBInputGroup, MDBSwitch } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { getLocalizedText } from "../../../utils/localizedText";
import ReactPDF from "@react-pdf/renderer";

const GeneralTab = ({ 
  event,
  industries,
  selectedIndustryIds,
  setSelectedIndustryIds,
  onInputChange,
  onImageUpload,
  onBadgePreviewUpload,
  onFileUpload,
  onRemoveFile,
  onDownloadFile,
  isLoading 
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
    <MDBRow>
      <MDBCol size="12" xl="3">
        {industries?.length > 0 && (
          <MDBSelect
            label={t("Select Industries")}
            multiple
            data={industries?.map(industry => ({
              text: getLocalizedText(industry.name),
              value: industry.id
            }))}
            value={selectedIndustryIds}
            onChange={(entries) => setSelectedIndustryIds(entries.map(entry => entry.value))}
          />
        )}
      </MDBCol>
      <MDBCol size="12" md="6" xl="3">
        <MDBSwitch
          label={event?.status === 'active' ? t('Active') : t('Disabled')}
          checked={event?.status === 'active'}
          onChange={(e) => onInputChange('status', e.target.checked ? 'active' : 'disabled')}
          
        />
      </MDBCol>
    </MDBRow>
    
   
      {/* Name Section */}
      <div className="my-2">
        <h5>{t("Name")}</h5>
        <MDBRow>
          <MDBCol size="12" xl="6">
            <MDBInputGroup className="mb-1">
              <div className="input-group-text">
                <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
              </div>
              <MDBInput
                label={t("German")}
                type="text"
                value={event?.name?.find((t) => t.language === "de")?.text || ""}
                onChange={(e) => onInputChange('name', e.target.value, 'de')}
              />
            </MDBInputGroup>
          </MDBCol>
          <MDBCol size="12" xl="6">
            <MDBInputGroup>
              <div className="input-group-text">
                <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
              </div>
              <MDBInput
                label={t("English")}
                type="text"
                value={event?.name?.find((t) => t.language === "en")?.text || ""}
                onChange={(e) => onInputChange('name', e.target.value, 'en')}
              />
            </MDBInputGroup>
          </MDBCol>
        </MDBRow>
      </div>

      {/* Type and Industry Section */}
      <h5 className="mt-4">{t("Settings")}</h5>
      <MDBRow>
        <MDBCol md="6" xl="3">
          <MDBSelect
            label={t("Select Event Type")}
            data={[
              { text: t("Select Event Type"), value: "" },
              { text: t("Fair"), value: "fair" },
              { text: t("Seminar"), value: "seminar" }
            ]}
            value={event.type}
            onChange={(value) => onInputChange('type', value.value)}
          />
        </MDBCol>
        
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            label={t('Landing Page URL')}
            type="url"
            value={event?.landingPageUrl || ''}
            onChange={(e) => onInputChange('landingPageUrl', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Start Date')}
            value={event?.startDate || ''}
            onChange={(e) => onInputChange('startDate', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('End Date')}
            value={event?.endDate || ''}
            onChange={(e) => onInputChange('endDate', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            type="time"
            label={t('Start Time')}
            value={event?.startTime || ''}
            onChange={(e) => onInputChange('startTime', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="time"
            label={t('End Time')}
            value={event?.endTime || ''}
            onChange={(e) => onInputChange('endTime', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <h6 className="mt-3">{t("Lectures and Actions")}</h6>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBSwitch
            label={t('Exhibitor lectures')}
            checked={event?.exhibitorLectures}
            onChange={(e) => onInputChange('exhibitorLectures', e.target.checked)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="1">
          <MDBInput
            type="number"
            min="0"
            step="1"
            label={t('Lecture rooms')}
            value={event?.lectureRooms || ''}
            onChange={(e) => onInputChange('lectureRooms', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Lecture registration start date')}
            value={event?.lectureRegistrationStartDate || ''}
            onChange={(e) => onInputChange('lectureRegistrationStartDate', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Lecture registration end date')}
            value={event?.lectureRegistrationEndDate || ''}
            onChange={(e) => onInputChange('lectureRegistrationEndDate', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Lecture edit end date')}
            value={event?.lectureEditEndDate || ''}
            onChange={(e) => onInputChange('lectureEditEndDate', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Lecture document upload end date')}
            value={event?.lectureDocumentsEndDate || ''}
            onChange={(e) => onInputChange('lectureDocumentsEndDate', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Lecture recording correction end date')}
            value={event?.lectureRecordingCorrectionEndDate || ''}
            onChange={(e) => onInputChange('lectureRecordingCorrectionEndDate', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Action registration start date')}
            value={event?.actionRegistrationStartDate || ''}
            onChange={(e) => onInputChange('actionRegistrationStartDate', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Action registration end date')}
            value={event?.actionRegistrationEndDate || ''}
            onChange={(e) => onInputChange('actionRegistrationEndDate', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Action edit end date')}
            value={event?.actionEditEndDate || ''}
            onChange={(e) => onInputChange('actionEditEndDate', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <h6 className="mt-3">{t("Booths")}</h6>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBSwitch
            label={t('Booth booking')}
            checked={event?.boothBooking}
            onChange={(e) => onInputChange('boothBooking', e.target.checked)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
      
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Equipment booking start date')}
            value={event?.startBoothEquipmentBooking || ''}
            onChange={(e) => onInputChange('startBoothEquipmentBooking', e.target.value)}
          />
        </MDBCol>
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Equipment booking end date')}
            value={event?.deadlineBoothEquipmentBooking || ''}
            onChange={(e) => onInputChange('deadlineBoothEquipmentBooking', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            type="date"
            label={t('Upload print files end date')}
            value={event?.deadlineBoothEquipmentPrint || ''}
            onChange={(e) => onInputChange('deadlineBoothEquipmentPrint', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      <h6 className="mt-3">{t("Ticket registration")}</h6>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBSwitch
            label={t('Enable ticket booking')}
            checked={event?.enableTicketBooking}
            onChange={(e) => onInputChange('enableTicketBooking', e.target.checked)}
          />
        </MDBCol>
       {event?.enableTicketBooking && <MDBCol md="6" xl="3">
        <MDBInput
            type="date"
            label={t('Ticket self cancelation deadline')}
            value={event?.ticketCancellationDeadline || ''}
            onChange={(e) => onInputChange('ticketCancellationDeadline', e.target.value)}
          />
        </MDBCol>}
      </MDBRow>
      {event?.enableTicketBooking &&
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBInput
            label={t('Suggested Invitation Code')}
            value={event?.suggestedInvitationCode || ''}
            onChange={(e) => onInputChange('suggestedInvitationCode', e.target.value)}
          />
        </MDBCol>
      </MDBRow>
      }
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBSwitch
            label={t('Invitation codes')}
            checked={event?.hasInvitationCodes}
            onChange={(e) => onInputChange('hasInvitationCodes', e.target.checked)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-3">
        <MDBCol md="6" xl="3">
          <MDBSwitch
            label={t('Selectable participant days')}
            checked={event?.bookingSelectDays}
            onChange={(e) => onInputChange('bookingSelectDays', e.target.checked)}
          />
        </MDBCol>
      </MDBRow>
      {/* Image Upload Section */}
      <h5 className="mt-4">{t("Image")}</h5>
      <MDBRow>
        <MDBCol md="6">
          <div className="text-center mb-4">
            <img
              src={event?.image?.fileName ? 
                "https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.image.fileName 
                : "https://via.placeholder.com/600x400"}
              className="img-thumbnail"
              style={{ height: "200px", width: "400px", objectFit: "cover" }}
              alt={t("Event image")}
            />
            <MDBFile 
              id='eventImage' 
              onChange={e => onImageUpload(e.target.files[0])} 
              className="mt-2"
            />
          </div>
        </MDBCol>
      </MDBRow>
      

      {/* Floor Plan Section */}
      <h5>{t('Floor Plan')}</h5>
      <MDBRow>
        <MDBCol md="6">
        {/* <ReactPDF
          file={event.floorPlan.s3Path}
        /> */}
      {event?.floorPlan?.fileName && (
        <div className="text-center mb-2">
          <MDBBtn 
            color="link" 
            onClick={() => onDownloadFile(event.floorPlan.s3Path)} 
            size="sm"
          >
            {event.floorPlan.fileName}
          </MDBBtn>
          <br />
          <MDBBtn 
            color="link" 
            className="text-danger" 
            size="sm" 
            onClick={() => onRemoveFile(event.floorPlan.s3Path)}
          >
            {t("Remove")}
          </MDBBtn>
        </div>
      )}
      <MDBFile 
        id='floorPlan'
        onChange={e => onFileUpload(e.target.files[0])}
        className="mb-4"
      />
      </MDBCol>
      </MDBRow>
      {/* Badge Preview Section */}
      <h5 className="mt-4">{t("Badge Preview")}</h5>
      <MDBRow>
        <MDBCol md="6">
          <div className="text-center mb-4">
            <img
              src={event?.badgePreview?.fileName ? 
                "https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.badgePreview.fileName 
                : "https://via.placeholder.com/500x600"}
              className="img-thumbnail"
              style={{ height: "400px", width: "327px", objectFit: "contain" }}
              alt={t("Badge preview")}
            />
            <MDBFile 
              id='badgePreview' 
              onChange={e => onBadgePreviewUpload(e.target.files[0])} 
              className="mt-2"
            />
          </div>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default GeneralTab;