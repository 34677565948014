import { generateClient } from 'aws-amplify/api';

const client = generateClient();

/**
 * Creates a log entry in the system
 * @param {Object} params - The logging parameters
 * @param {string} params.type - The type of log entry (e.g., 'ERROR', 'INFO', 'WARNING', 'SECURITY', etc.)
 * @param {string} params.message - The log message
 * @param {string} [params.userId] - Optional user ID associated with the log
 * @param {Object} [params.additionalData] - Optional additional data to include in the message
 * @returns {Promise<Object>} The created log entry
 */
export const createLog = async ({ type, message, userId = null, additionalData = {} }) => {
  try {
    // Get IP and User Agent
    let ip = null;
    let userAgent = null;

    if (typeof window !== 'undefined') {
      // Note: Getting IP on client side is limited. Consider using a server-side service
      // for accurate IP tracking if needed
      userAgent = window.navigator.userAgent;
    }

    // Format message if additional data is provided
    const formattedMessage = additionalData && Object.keys(additionalData).length > 0
      ? `${message} | ${JSON.stringify(additionalData)}`
      : message;

    const logEntry = {
      timestamp: new Date().toISOString(),
      type: type.toUpperCase(),
      message: formattedMessage,
      user: userId,
      ip,
      userAgent
    };

    const response = await client.graphql({
      query: `
        mutation CreateLog(
          $input: CreateLogInput!
        ) {
          createLog(input: $input) {
            id
            timestamp
            type
            message
            user
            ip
            userAgent
          }
        }
      `,
      variables: {
        input: logEntry
      }
    });

    return response.data.createLog;
  } catch (error) {
    console.error('Error creating log:', error);
    // Optionally rethrow the error if you want calling code to handle it
    throw error;
  }
};

/**
 * Convenience function for creating error logs
 */
export const logError = async ({ message, userId = null, additionalData = {} }) => {
  return createLog({ type: 'ERROR', message, userId, additionalData });
};

/**
 * Convenience function for creating info logs
 */
export const logInfo = async ({ message, userId = null, additionalData = {} }) => {
  return createLog({ type: 'INFO', message, userId, additionalData });
};

/**
 * Convenience function for creating warning logs
 */
export const logWarning = async ({ message, userId = null, additionalData = {} }) => {
  return createLog({ type: 'WARNING', message, userId, additionalData });
};

/**
 * Convenience function for creating security logs
 */
export const logSecurity = async ({ message, userId = null, additionalData = {} }) => {
  return createLog({ type: 'SECURITY', message, userId, additionalData });
};

/**
 * Convenience function for creating audit logs
 */
export const logAudit = async ({ message, userId = null, additionalData = {} }) => {
  return createLog({ type: 'AUDIT', message, userId, additionalData });
};