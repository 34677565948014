import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCheckbox, MDBTypography } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { isoToGermanDate } from "../../utils/dateTools";
import { deleteTicket } from "../../graphql/mutations";
import { generateClient } from 'aws-amplify/api';
import { useEffect, useRef, useState } from "react";
import { getEvent as getEventQuery } from "../../graphql/queries";
import getEventDays from "../../utils/getEventDays";
import QRCode from 'qrcode.react';
import shortUUID from 'short-uuid';

const client = generateClient();

export default function ShowTicket({ticket, handleDeleteTicket}) {
    const { t, i18n } = useTranslation();

    const clickDeleteTicket = async (id) => {
        try {
          const data = await client.graphql({
            query: /* GraphQL */ `
            mutation DeleteTicket(
              $input: DeleteTicketInput!
              $condition: ModelTicketConditionInput
            ) {
              deleteTicket(input: $input, condition: $condition) {
                id
                }
            }
            `,
                
            variables: { input: { id } },
            })
            console.log(data)
            handleDeleteTicket(id);
            // setEmployees(employees.map(employee => {
            //     employee.user.tickets.items = employee.user.tickets.items.filter(ticket => ticket.id !== id);
            //     return employee;
            // }))
        }
        catch (error) {
          console.error(error)
        }
    }

    

    const [event, setEvent] = useState();
    const [eventDays, setEventDays] = useState();

    const getEvent = async () => {
        if(ticket?.event?.id) {
            try {
              const eventData = await client.graphql({
                query: getEventQuery,
                variables: { id: ticket.event.id } });
                setEvent(eventData.data.getEvent);
                setEventDays(getEventDays(eventData.data.getEvent));
            } catch (error) {
              console.error(error);
              
            }
        }
    }
    const [selectedDays, setSelectedDays] = useState(ticket?.eventDays || []);
    useEffect(() => {
        setSelectedDays(ticket?.eventDays || []);
    }, [ticket?.eventDays]);
    const handleChangeDays = async () => {
        try {
            const data = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateTicket(
                  $input: UpdateTicketInput!
                  $condition: ModelTicketConditionInput
                ) {
                  updateTicket(input: $input, condition: $condition) {
                    id
                  }
                }
              `,
                variables: { input: { id: ticket?.id, eventDays: selectedDays } },
            })
            console.log(data)
            setIsEditDays(false);
        } catch (error) {
            console.error(error)
        }
    }

    const [isEditDays, setIsEditDays] = useState(false);

    const handleEditDays = () => {
        setIsEditDays(true);
        getEvent();
    }


    // Only show download button 30 seconds after ticket.createdAt, not bound to component or page load
    const [showDownload, setShowDownload] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (new Date(ticket?.createdAt).getTime() + 10000 < new Date().getTime()) {
                setShowDownload(true);
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [ticket?.createdAt]);


    const qrRef = useRef(null);


    
    // Funktion zum Herunterladen des QR-Codes
    const downloadQRCode = () => {
        const canvas = qrRef.current.querySelector('canvas');
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = `QRCode Ticket Lounges 2024 - ${ticket.personalAddress.firstName} ${ticket.personalAddress.lastName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
 <>
<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>{t("Ticket")}</MDBCardTitle>
        <MDBCardText>
            {/* <a href={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/tickets/" + ticket?.id + ".pdf"} target="_blank" rel="noreferrer">{t("Download ticket")}</a> */}
            {
                showDownload ? 
                <>
                <a href={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/tickets/" + ticket?.id + ".pdf"} target="_blank" rel="noreferrer" download>{t("Download ticket")}</a>
                <br/>
                <span style={{fontSize: "10px", textWrap: "nowrap"}}>{"https://iec-cp-public.s3.eu-central-1.amazonaws.com/tickets/" + ticket?.id + ".pdf"}</span>
                </>
               
                : <>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <br/>
                    <span>{t("Generating ticket")}</span>
                </>
            }
            {ticket?.id && 
                <div ref={qrRef}>
            <QRCode
                value={shortUUID().fromUUID(ticket?.id)}
                size={128}
                level={"H"}
                includeMargin={true}
                
            />
            <br/>
            <MDBBtn color="link" className="px-0" onClick={downloadQRCode}>{t("Download QR-Code")}</MDBBtn>
            <p>
                {t("This QR code will be used at the event to identify your booking. You can use it instead of your ticket.")}
            </p>
            </div>}
        </MDBCardText>
        <MDBCardTitle>{t("Event")}</MDBCardTitle>
        <MDBCardText>{ticket?.event?.name.find(name => name.language === i18n.language)?.text || ticket?.event?.name[0]?.text}</MDBCardText>
        {/* <MDBCardTitle>{t("Ticket type")}</MDBCardTitle>
        <MDBCardText>{ticket?.type === "default" ? t("Visitor ticket") : t("Premium ticket")}</MDBCardText> */}
        <MDBCardTitle>{t("Days")} 
            <MDBBtn
                className='mx-2 my-0 py-0'
                color='tertiary'
                rippleColor='light'
                disabled={ticket?.event?.ticketCancellationDeadline && new Date(ticket.event.ticketCancellationDeadline) < new Date()}
                onClick={() => handleEditDays()}>{t("Change")}
            </MDBBtn>
        </MDBCardTitle>
       {isEditDays ? 
       <>
       { eventDays && eventDays.map((day, index) => {
        return (
            <MDBCheckbox
            key={index}
            label={isoToGermanDate(day)}
            id={"day" + index}
            checked={selectedDays?.includes(day)}
            onChange={e => {
                if(e.target.checked) {
                setSelectedDays(old => ([...old, day]).filter(Boolean));
                } else {
                setSelectedDays(old => old.filter(d => d !== day).filter(Boolean));
                }
            }}
            />
        )}
        )}
       <MDBBtn className="mt-2" color="primary" size="sm" onClick={() => handleChangeDays()}>{t("Save")}</MDBBtn>
       </>
       
        :
        <MDBCardText>{selectedDays.map((day, index) => (index !== 0 ? ", " : "" ) +isoToGermanDate(day))}</MDBCardText>
        }

        
        <MDBCardTitle>{t("Booking date")}</MDBCardTitle>
        <MDBCardText>{isoToGermanDate(ticket?.createdAt)}</MDBCardText>
        <MDBCardTitle>{t("Invoice")}</MDBCardTitle>
        <MDBCardText>{ticket?.type === "default" ? t("No invoice available for free ticket.") :t("You will receive the invoice by email.")}</MDBCardText>
        <MDBCardTitle>{t("Actions")}</MDBCardTitle>
        {ticket?.event?.ticketCancellationDeadline ? 
        (
            ticket?.event?.ticketCancellationDeadline >= new Date().toISOString().substring(0,10) ?
            <>
            <MDBTypography note noteColor="success">{t("You can cancel this ticket until")} {isoToGermanDate(ticket?.event?.ticketCancellationDeadline)}</MDBTypography>
            <MDBBtn color="danger" size="sm"
                onClick={() => {
                    if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                        clickDeleteTicket(ticket?.id);
                    }
                }}
                >{t("Cancel ticket")}</MDBBtn>
            </>
            :
            <MDBTypography note noteColor="danger">{t("You cannot cancel this ticket anymore please contact the support.")}</MDBTypography>
        )
        :
        <MDBBtn color="danger" size="sm"
        onClick={() => {
            if (window.confirm(t("Are you sure you want to delete this ticket?"))) {
                clickDeleteTicket(ticket?.id);
            }
        }}
        >{t("Cancel ticket")}</MDBBtn>
        }
        
        
    </MDBCardBody>
</MDBCard>
 </>
  )
}
