import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBContainer,
  MDBNavbar,
  MDBInput,
  MDBInputGroup,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBCollapse,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";
import LangSelect from "./components-user/LangSelect";
import { UserContext } from "./App";
import { useIndustry } from "./utils/industryContextHook";
import { getLocalizedText } from "./utils/localizedText";

export default function Navigation(props) {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const { currentIndustry, switchIndustry, availableIndustries } = useIndustry();
  const isAdmin = props.isAdmin;

  const [openNavSections, setOpenNavSections] = useState(["accordionprofile", "accordionmycompany"]);
  const toggleNavSection = (value) => {
    console.log("toggleNavSection", value);
    if (openNavSections.includes(value)) {
      setOpenNavSections(
        openNavSections.filter((item) => item !== value)
      );
    } else {
      setOpenNavSections([...openNavSections, value]);
    }
  };

  const [basicOpen, setBasicOpen] = useState(false);
  const [mode, setMode] = useState("side");
  const [showBasic, setShowBasic] = useState(false);



  const handleResize = useCallback(() => {
    if (!window.matchMedia("screen and (min-width: 992px)").matches) {
      setMode("side");
      return setBasicOpen(false);
    }
    setMode("push");
    return setBasicOpen(true);
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <>
      <header>
       
          <MDBSideNav
            // style={{zIndex: 1050}}
            backdrop={false}
            mode={mode}
            open={basicOpen}
            getOpenState={setBasicOpen}
            bgColor="primary"
          >
           <div className="d-flex justify-content-center py-4">
              {currentIndustry?.logo && (
                <img
                  id="MDB-logo"
                  src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/industryLogos/${currentIndustry.logo.fileName}`}
                  alt={currentIndustry.name.find(n => n.language === i18n.language)?.text || "Industry Logo"}
                  draggable="false"
                  height="65px"
                />
              )}
            </div>
            <MDBSideNavMenu>
              <MDBSideNavItem>
                <Link to="/" className="ripple ripple-surface sidenav-link">
                  <MDBIcon icon="home" className="fas me-3" />
                  {t("Dashboard")}
                </Link>
              </MDBSideNavItem>
              {/* <MDBSideNavItem>
            <hr className="mx-3 my-0"></hr>
        </MDBSideNavItem> */}
              {/* <MDBSideNavItem>
                <Link
                  to="/myprofile"
                  className="ripple ripple-surface sidenav-link"
                >
                  <img
                    src={
                      user?.attributes?.["custom:profileImage"]
                        ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" +
                          user?.attributes?.["custom:profileImage"]
                        : user?.image?.fileName
                        ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" +
                          user.image.fileName
                        : "https://mdbootstrap.com/img/new/avatars/2.jpg"
                    }
                    className="rounded-circle me-1"
                    height="22"
                    alt=""
                    loading="lazy"
                  />
                  {t("Personal Profile")}
                </Link>
              </MDBSideNavItem> */}
              {/* <MDBSideNavItem>
            <hr className="mx-3 my-0"></hr>
        </MDBSideNavItem> */}
              {/* <MDBSideNavItem>
        <MDBSideNavLink className="text-center">Meine Unternehmen</MDBSideNavLink>
        </MDBSideNavItem> */}

        <MDBSideNavItem>
                <MDBSideNavLink
                  icon="angle-down"
                  shouldBeExpanded={openNavSections.includes("accordionprofile")}
                  onClick={() => toggleNavSection("accordionprofile")}
                >
                <MDBIcon icon="user" className="fas me-3" />
                  {t("My profiles")}
                </MDBSideNavLink>
                <MDBSideNavCollapse
                  id="accordionprofile"
                  open={openNavSections.includes("accordionprofile")}
                >
                 <Link
                  to="/myprofile"
                  className="ripple ripple-surface sidenav-link"
                >
                  <img
                    src={
                      user?.attributes?.["custom:profileImage"]
                        ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" +
                          user?.attributes?.["custom:profileImage"]
                        : user?.image?.fileName
                        ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" +
                          user.image.fileName
                        : "https://via.placeholder.com/25x25"
                    }
                    className="rounded-circle me-2"
                    height="22"
                    width="22"
                    style={{objectFit: "cover"}}
                    alt=""
                    loading="lazy"
                  />
                  {t("Personal Profile")}
                </Link>
                
                  {user?.employeeProfiles?.items?.filter(ep => ep.status === "active").map((item) => (
                    <Link
                    key={item.id}
                    to={"/mycompany/"+ item.company.id +"/myemployeeprofile/" + item.id}
                    className="ripple ripple-surface sidenav-link"
                  >
                    <img
                    src={item.company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + item.company.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                    className="rounded-circle bg-white me-2"
                    height="22"
                    width="22"
                    alt=""
                    loading="lazy"
                  />
                    Mitarbeiter Profil
                  </Link>
                  ))}
                </MDBSideNavCollapse>
              </MDBSideNavItem>
              {
                user?.employeeProfiles?.items?.filter(ep => ep.status === "active").map((item) => {
                  const company = item.company;
                  return <MDBSideNavItem key={item.id}>
                    <MDBSideNavLink
                      icon="angle-down"
                      shouldBeExpanded={openNavSections.includes("accordionmycompany" + company.id)}
                      onClick={() => toggleNavSection("accordionmycompany" + company.id)}
                    >
                      <img
                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + company.image?.main?.fileName}
                        className="rounded-circle bg-white me-2"
                        height="22"
                        width="22"
                        alt=""
                        loading="lazy"
                      />
                      {company.name}
                    </MDBSideNavLink>
                    <MDBSideNavCollapse
                      id={"accordionmycompany" + company.id}
                      open={openNavSections.includes("accordionmycompany" + company.id)}
                    >
                     
                      <Link
                        to={"/mycompany/"+ company.id +"/profile"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Company Profile")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/billing"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Billing Settings")}
                      </Link>

                      {/* <Link
                        to={"/mycompany/"+ company.id +"/departments"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Departments")}
                      </Link> */}
                      <Link
                        to={"/mycompany/"+ company.id +"/employees"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Employees")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/company-bookings"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Bookings")}
                      </Link>
                      {/* <Link
                        to="/mycompany/requests"
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Requests")}
                      </Link> */}
                      <Link
                        to={"/mycompany/"+ company.id +"/lectures"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Lectures")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/actions"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Promotions")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/recruiting-lectures"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Recruiting")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/news"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("News")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/products"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Products")}
                      </Link>
                      <Link
                        to={"/mycompany/"+ company.id +"/event-guests"}
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Event Guests")}
                      </Link>
                      
                      {/* <Link
                        
                        className="ripple ripple-surface sidenav-link"
                      >
                        {t("Actions (from 01.02.24)")}
                      </Link> */}
                    </MDBSideNavCollapse>
                  </MDBSideNavItem>
                })
                }

              {/* <MDBSideNavItem>
            <MDBSideNavLink
            icon='angle-down'
            shouldBeExpanded={collapseOpened === 'accordionmycompany2'}
            onClick={() => toggleAccordion('accordionmycompany2')}
            >
            <img
                src='https://iec-cp-public.s3.eu-central-1.amazonaws.com/logoicon2x.png'
                className='rounded-circle me-2'
                height='22'
                alt=''
                loading='lazy'
            />
            ChannelHive
            </MDBSideNavLink>
            <MDBSideNavCollapse id='accordionmycompany' open={collapseOpened === 'accordionmycompany2'}>
            <MDBSideNavLink>{t("My Profile")}</MDBSideNavLink>
            <MDBSideNavLink>{t("Colleagues")}</MDBSideNavLink>
            </MDBSideNavCollapse>
        </MDBSideNavItem> */}

              {/* <MDBSideNavItem>
            <hr className="mx-3 my-0"></hr>
        </MDBSideNavItem> */}
              {/* <MDBSideNavItem>
                <Link
                  to="/mycompany/08c491a9-9128-41d7-bc67-1ef292e5c365/register-lecture-landing-page"
                  className="ripple ripple-surface sidenav-link"
                >
                  <MDBIcon icon="chalkboard-teacher" className="fas me-3" />
                  {t("Lectures")}
                </Link>
              </MDBSideNavItem> */}

              <MDBSideNavItem>
                <Link
                  to="/event-lectures"
                  className="ripple ripple-surface sidenav-link"
                >
                  <MDBIcon icon="chalkboard-teacher" className="fas me-3" />
                  {t("Event Lectures")}
                </Link>
              </MDBSideNavItem>
             
              {/* <MDBSideNavItem>
              <Link
                to="/insight/lectureVideos"
                className="ripple ripple-surface sidenav-link"
              >
                <MDBIcon icon="video" className="fas me-3" />
                {t("Premium Videos")}
              </Link>
            </MDBSideNavItem> */}
            <MDBSideNavItem>
                <MDBSideNavLink
                icon='angle-down'
                shouldBeExpanded={openNavSections.includes("academy")}
                onClick={() => toggleNavSection("academy")}
                >
                <MDBIcon icon='graduation-cap' className='fas me-3' />
                Academy
                </MDBSideNavLink>
                <MDBSideNavCollapse id='accordionCollapseinsight' open={openNavSections.includes("academy")}>
                <Link to="/academy/basics" className="ripple ripple-surface sidenav-link">{t("Basic Courses")}</Link>
                {/* <Link to="" className="ripple ripple-surface sidenav-link">{t("Live Seminars")}</Link> */}
                <a href="https://cleanroom-processes.de/en/cp-academy/academy-live-seminare/" target="_blank" rel="noreferrer" className="ripple ripple-surface sidenav-link">{t("Live Seminars")}</a> 
                </MDBSideNavCollapse>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <Link
                  to="/shop
                  "
                  className="ripple ripple-surface sidenav-link"
                >
                  <MDBIcon icon="shopping-bag" className="fas me-3" />
                  {t("Shop")}
                </Link>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <Link
                  to="/bookings
                  "
                  className="ripple ripple-surface sidenav-link"
                >
                  <MDBIcon icon="shopping-bag" className="fas me-3" />
                  {t("Bookings & Tickets")}
                </Link>
              </MDBSideNavItem>
              {/* {user?.["cognito:groups"]?.includes("Admin") && (
              <MDBSideNavItem>
                <Link
                  to="/chat"
                  className="ripple ripple-surface sidenav-link"
                >
                  <MDBIcon icon="comments" className="fas me-3" />
                  {t("Chat")}
                </Link>
              </MDBSideNavItem>
              )} */}
              {/* <MDBSideNavItem>
                <Link
                  to=""
                  className="ripple ripple-surface sidenav-link grey-text text-muted"
                >
                  <MDBIcon icon="building" className="fas me-3" />
                  {t("All Companies")}
                </Link>
              </MDBSideNavItem>
              <MDBSideNavItem></MDBSideNavItem>
              <MDBSideNavItem>
                <Link
                  to=""
                  className="ripple ripple-surface sidenav-link grey-text text-muted"
                >
                  <MDBIcon icon="calendar-alt" className="fas me-3" />
                  {t("Events")}
                </Link>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <Link
                  to=""
                  className="ripple ripple-surface sidenav-link disabled text-muted"
                >
                  <MDBIcon icon="comments" className="fas me-3" />
                  {t("Messages")}
                </Link>
              </MDBSideNavItem> */}

              {/* <MDBSideNavItem
                style={{
                  bottom: "0",
                  position: "fixed",
                  width: "230px",
                  paddingRight: ".3rem",
                }}
              >
                <MDBSideNavLink
                  icon="angle-down"
                  shouldBeExpanded={openNavSections.includes("accordionAccount")}
                  onClick={() => toggleNavSection("accordionAccount")}
                >
                  <MDBIcon icon="cogs" className="fa-fw me-3" />
                  {t("Account")}
                </MDBSideNavLink>
                <MDBSideNavCollapse
                  id="accordionCollapse1"
                  open={openNavSections.includes("accordionAccount")}
                >
                  <MDBSideNavLink>{t("Profile")}</MDBSideNavLink>
                  <MDBSideNavLink>{t("Password")}</MDBSideNavLink>
                </MDBSideNavCollapse>
              </MDBSideNavItem> */}
              <MDBSideNavItem
        style={{
          bottom: "0",
          position: "fixed",
          width: "230px",
          paddingRight: ".3rem",
        }}
      >
        <MDBSideNavLink
          icon="angle-down"
          shouldBeExpanded={openNavSections.includes("accordionIndustry")}
          onClick={() => toggleNavSection("accordionIndustry")}
        >
          <MDBIcon icon="building" className="fa-fw me-3" />
          {getLocalizedText(currentIndustry?.name)}
        </MDBSideNavLink>
        <MDBSideNavCollapse
          id="accordionIndustry"
          open={openNavSections.includes("accordionIndustry")}
        >
          {availableIndustries.map((industry) => (
            <MDBSideNavLink
              key={industry.id}
              onClick={() => switchIndustry(industry)}
              className={currentIndustry?.id === industry.id ? 'active' : ''}
            >
              <img
                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/industryLogos/${industry.logo?.fileName}`}
                className="rounded-circle me-2"
                height="20"
                width="20"
                alt=""
              />
              {getLocalizedText(industry.name)}
            </MDBSideNavLink>
          ))}
        </MDBSideNavCollapse>
      </MDBSideNavItem>
            </MDBSideNavMenu>
            <div className="d-inline d-md-none fixed-bottom">
              <LangSelect />
            </div>
            <MDBIcon
              fas
              className="d-lg-none"
              icon="angle-double-left"
              onClick={() => {
                setBasicOpen(!basicOpen);
              }}
              style={{
                position: "fixed",
                top: "1rem",
                right: "1rem",
              }}
            />
            <>
              <div
                className={
                  "d-lg-none " +
                  (basicOpen ? "sidenav-overlay" : "sidenav-overlay d-none")
                }
                style={{
                  width: "100vw",
                  height: "100vh",
                  position: "fixed",
                  top: "0",
                  left: "240px",
                }}
                onClick={() => {
                  setBasicOpen(!basicOpen);
                }}
              ></div>
            </>
          </MDBSideNav>

        <MDBNavbar
          fixed="top"
          expand="lg"
          id="main-navbar"
          bgColor="secondary"
          style={{ minHeight: "3.3rem" }}
        >
          <MDBContainer fluid>
            <MDBNavbarToggler
              className="d-flex"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setBasicOpen(!basicOpen)}
            >
              <MDBIcon icon="bars" fas />
            </MDBNavbarToggler>
            <div className="input-group d-flex justify-content-center my-auto mainSearch">

              </div>

<MDBCollapse navbar open={true} className="d-flex ms-2 ms-md-0"  style={{maxHeight: "50px"}}>
            <MDBNavbarNav right fullWidth={false}>
                {/* <MDBNavbarItem>
                <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link text-dark'>
                    <MDBIcon icon='bell' />
                    <MDBBadge notification color='danger' pill>
                    1
                    </MDBBadge>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem link>Action
                    </MDBDropdownItem>
                    <MDBDropdownItem link>Another action
                    </MDBDropdownItem>
                    <MDBDropdownItem link>Something else here
                    </MDBDropdownItem>
                </MDBDropdownMenu>
                </MDBDropdown>
            </MDBNavbarItem> */}
                <MDBNavbarItem className="d-none d-md-inline">
                  <LangSelect />
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <MDBDropdown>
                    <MDBDropdownToggle tag="a" className="nav-link">
                    <img
                    src={
                      user?.attributes?.["custom:profileImage"]
                        ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" +
                          user?.attributes?.["custom:profileImage"]
                        : user?.image?.fileName
                        ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" +
                          user.image.fileName
                        : "https://via.placeholder.com/25x25"
                    }
                    className="rounded-circle"
                    height="22"
                    alt=""
                    loading="lazy"
                  />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      
                        
                          <MDBDropdownItem
                          link
                          onClick={() => props.setAdminAsUser(false)}
                          style={{display: props.isAdmin ? "block" : "none"}}
                        >
                         { props.isAdmin && "Switch to Admin"}

                        </MDBDropdownItem>
                      
                      {/* <MDBDropdownItem link>My profile</MDBDropdownItem>
                      <MDBDropdownItem link>Settings</MDBDropdownItem> */}
                      <MDBDropdownItem link onClick={() => signOut()}>
                        Logout
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </header>
    </>
  );
}
