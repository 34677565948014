/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      sortKey
      lectures {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videos
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      type
      duration
      price
      topics {
        language
        text
        __typename
      }
      speakersDescription {
        language
        text
        __typename
      }
      CTADescription {
        language
        text
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      WPJetEngineId
      academyTopics {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAcademyTopic = /* GraphQL */ `
  query GetAcademyTopic($id: ID!) {
    getAcademyTopic(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      courses {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seminars {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      academyTopicGroupAcademyTopicsId
      __typename
    }
  }
`;
export const listAcademyTopics = /* GraphQL */ `
  query ListAcademyTopics(
    $filter: ModelAcademyTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcademyTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAcademyTopicGroup = /* GraphQL */ `
  query GetAcademyTopicGroup($id: ID!) {
    getAcademyTopicGroup(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAcademyTopicGroups = /* GraphQL */ `
  query ListAcademyTopicGroups(
    $filter: ModelAcademyTopicGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAcademyTopicGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSeminar = /* GraphQL */ `
  query GetSeminar($id: ID!) {
    getSeminar(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      cover {
        fileName
        alt
        x4UUID
        __typename
      }
      seminarDays {
        date
        startTime
        endTime
        notice {
          language
          text
          __typename
        }
        __typename
      }
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      speakers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      bookingStartDate
      bookingEndDate
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      academyTopics {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      sortKey
      WPJetEngineId
      createdAt
      updatedAt
      seminarProductId
      __typename
    }
  }
`;
export const listSeminars = /* GraphQL */ `
  query ListSeminars(
    $filter: ModelSeminarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeminars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      owner
      id
      industryId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdByUserId
      userTicketsId
      eventDays
      status
      paymentStatus
      type
      visitorType
      addons
      payment {
        id
        orderFinancial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        status
        isPayed
        amount
        currency
        paymentProvider
        transactionId
        paymentDate
        paymentAmount
        paymentCurrency
        createdAt
        updatedAt
        orderFinancialPaymentsId
        __typename
      }
      ticketFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      notes
      personalAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      invitationCode
      orderNumber
      firstName
      lastName
      companyName
      scans {
        items {
          id
          scannedAt
          scannedLocation
          scannedDevice
          createdAt
          updatedAt
          ticketScansId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      ticketEventId
      ticketCompanyId
      ticketPaymentId
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTickets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTickets = /* GraphQL */ `
  query SearchTickets(
    $filter: SearchableTicketFilterInput
    $sort: [SearchableTicketSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTicketAggregationInput]
  ) {
    searchTickets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicketScan = /* GraphQL */ `
  query GetTicketScan($id: ID!) {
    getTicketScan(id: $id) {
      id
      ticket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      scannedAt
      scannedLocation
      scannedDevice
      createdAt
      updatedAt
      ticketScansId
      owner
      __typename
    }
  }
`;
export const listTicketScans = /* GraphQL */ `
  query ListTicketScans(
    $filter: ModelTicketScanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketScans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ticket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        scannedAt
        scannedLocation
        scannedDevice
        createdAt
        updatedAt
        ticketScansId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvitationCode = /* GraphQL */ `
  query GetInvitationCode($id: ID!) {
    getInvitationCode(id: $id) {
      id
      createdByAdmin
      code
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      companyInvitationCodesId
      eventInvitationCodesId
      owner
      __typename
    }
  }
`;
export const listInvitationCodes = /* GraphQL */ `
  query ListInvitationCodes(
    $filter: ModelInvitationCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitationCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdByAdmin
        code
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdAt
        updatedAt
        companyInvitationCodesId
        eventInvitationCodesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invitationCodeByCode = /* GraphQL */ `
  query InvitationCodeByCode(
    $code: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationCodeByCode(
      code: $code
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdByAdmin
        code
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdAt
        updatedAt
        companyInvitationCodesId
        eventInvitationCodesId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      visibleInShop
      name {
        language
        text
        __typename
      }
      name_text
      category {
        id
        name {
          language
          text
          __typename
        }
        customerTypes
        description {
          language
          text
          __typename
        }
        sortKey
        image {
          __typename
        }
        products {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      invoiceName {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      includes {
        language
        text
        __typename
      }
      sku
      ean
      type
      subType
      price
      downPaymentPrice
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      orders {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      orderLineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      allowedBuyerGroups
      requiredShipping
      icon
      stock
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      availableInIndustries {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableInEvents {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          parentProductID
          componentProductID
          qty
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      productCategoryProductsId
      bundleBoothAvaiableAccessoriesId
      bundleBoothIncludedAccessoriesId
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productsBySku = /* GraphQL */ `
  query ProductsBySku(
    $sku: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsBySku(
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productsByType = /* GraphQL */ `
  query ProductsByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: [SearchableProductSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductAggregationInput]
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($id: ID!) {
    getProductCategory(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      customerTypes
      description {
        language
        text
        __typename
      }
      sortKey
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      products {
        items {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        customerTypes
        description {
          language
          text
          __typename
        }
        sortKey
        image {
          __typename
        }
        products {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductComponent = /* GraphQL */ `
  query GetProductComponent($id: ID!) {
    getProductComponent(id: $id) {
      id
      parentProductID
      parentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      componentProductID
      componentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductComponents = /* GraphQL */ `
  query ListProductComponents(
    $filter: ModelProductComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentProductID
        parentProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        componentProductID
        componentProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        qty
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productComponentsByParentProductID = /* GraphQL */ `
  query ProductComponentsByParentProductID(
    $parentProductID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productComponentsByParentProductID(
      parentProductID: $parentProductID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentProductID
        parentProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        componentProductID
        componentProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        qty
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const productComponentsByComponentProductID = /* GraphQL */ `
  query ProductComponentsByComponentProductID(
    $componentProductID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productComponentsByComponentProductID(
      componentProductID: $componentProductID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentProductID
        parentProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        componentProductID
        componentProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        qty
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventProduct = /* GraphQL */ `
  query GetEventProduct($id: ID!) {
    getEventProduct(id: $id) {
      id
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      price
      stock
      isAvailable
      createdAt
      updatedAt
      productEventProductsId
      eventEventProductsId
      __typename
    }
  }
`;
export const listEventProducts = /* GraphQL */ `
  query ListEventProducts(
    $filter: ModelEventProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        price
        stock
        isAvailable
        createdAt
        updatedAt
        productEventProductsId
        eventEventProductsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStockReservation = /* GraphQL */ `
  query GetStockReservation($id: ID!) {
    getStockReservation(id: $id) {
      id
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      order {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryOrdersId
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      status
      reservationDate
      reservationEndDate
      createdAt
      updatedAt
      productStockReservationsId
      orderStockReservationsId
      companyStockReservationsId
      userStockReservationsId
      __typename
    }
  }
`;
export const listStockReservations = /* GraphQL */ `
  query ListStockReservations(
    $filter: ModelStockReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStockReservations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        qty
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        status
        reservationDate
        reservationEndDate
        createdAt
        updatedAt
        productStockReservationsId
        orderStockReservationsId
        companyStockReservationsId
        userStockReservationsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        orders {
          nextToken
          __typename
        }
        jetEngineAccess {
          baseurl
          username
          password
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      orderNumber
      buyerOrderNumber
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      paymentStatus
      deliveryStatus
      products {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      notes
      deliveryAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      financial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      data
      totalAmount
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      industryOrdersId
      companyOrdersId
      userOrdersId
      orderFinancialId
      owner
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryOrdersId
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: [SearchableOrderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrderAggregationInput]
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryOrdersId
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrderFinancial = /* GraphQL */ `
  query GetOrderFinancial($id: ID!) {
    getOrderFinancial(id: $id) {
      id
      isBusiness
      isInterEUDelivery
      vatInfo {
        vatNumber
        country
        __typename
      }
      currency
      documents {
        documentType
        s3Path
        documentNumber
        referingDocumentNumber
        documentName
        status
        date
        amount
        amountNet
        sentToCustomerTime
        sentToCustomerAddresses
        __typename
      }
      invoiceAmount
      invoiceAmountNet
      invoiceNumber
      invoiceDate
      invoiceFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      invoiceLastSent
      invoiceReceivers
      servicePeriodStart
      servicePeriodEnd
      taxRate
      shippingCost
      shippingCostNet
      paymentCost
      order {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryOrdersId
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      payments {
        items {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        nextToken
        __typename
      }
      discountCode
      discountAmount
      discountType
      paymentProvider
      totalAmount
      createdAt
      updatedAt
      orderFinancialOrderId
      __typename
    }
  }
`;
export const listOrderFinancials = /* GraphQL */ `
  query ListOrderFinancials(
    $filter: ModelOrderFinancialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderFinancials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderLineItem = /* GraphQL */ `
  query GetOrderLineItem($id: ID!) {
    getOrderLineItem(id: $id) {
      id
      order {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryOrdersId
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      parentLineItemId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      productID
      qty
      price
      downPaymentPrice
      discount
      discountType
      taxRate
      taxAmount
      bookedBooth {
        id
        type
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      bookedBoothEquipment {
        id
        bookedBooth {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        qty
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        status
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        data
        createdAt
        updatedAt
        bookedBoothEquipmentId
        boothEquipmentOrderLineItemId
        __typename
      }
      bookedTicket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      createdAt
      updatedAt
      productOrderLineItemsId
      orderLineItemsId
      orderLineItemProductId
      orderLineItemBookedBoothId
      orderLineItemBookedBoothEquipmentId
      orderLineItemBookedTicketId
      __typename
    }
  }
`;
export const listOrderLineItems = /* GraphQL */ `
  query ListOrderLineItems(
    $filter: ModelOrderLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderLineItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      orderFinancial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      status
      isPayed
      amount
      currency
      paymentProvider
      transactionId
      paymentDate
      paymentAmount
      paymentCurrency
      createdAt
      updatedAt
      orderFinancialPaymentsId
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderFinancial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        status
        isPayed
        amount
        currency
        paymentProvider
        transactionId
        paymentDate
        paymentAmount
        paymentCurrency
        createdAt
        updatedAt
        orderFinancialPaymentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBundleBooth = /* GraphQL */ `
  query GetBundleBooth($id: ID!) {
    getBundleBooth(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      sku
      boothNumber
      availableInEvents {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      price
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      status
      availablePrints {
        items {
          id
          dimensions
          type
          createdAt
          updatedAt
          bundleBoothAvailablePrintsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableFloors {
        items {
          id
          color
          createdAt
          updatedAt
          bundleBoothAvaiableFloorsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableAccessories {
        items {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      includedMarketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      includedAccessories {
        items {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBundleBooths = /* GraphQL */ `
  query ListBundleBooths(
    $filter: ModelBundleBoothFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBundleBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookedBooth = /* GraphQL */ `
  query GetBookedBooth($id: ID!) {
    getBookedBooth(id: $id) {
      id
      type
      orderLineItem {
        id
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      boothNumber
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      equipment {
        items {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyBookedBoothsId
      eventBookedBoothsId
      bookedBoothOrderLineItemId
      __typename
    }
  }
`;
export const listBookedBooths = /* GraphQL */ `
  query ListBookedBooths(
    $filter: ModelBookedBoothFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookedBooths(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchBookedBooths = /* GraphQL */ `
  query SearchBookedBooths(
    $filter: SearchableBookedBoothFilterInput
    $sort: [SearchableBookedBoothSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBookedBoothAggregationInput]
  ) {
    searchBookedBooths(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getBoothEquipment = /* GraphQL */ `
  query GetBoothEquipment($id: ID!) {
    getBoothEquipment(id: $id) {
      id
      bookedBooth {
        id
        type
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      qty
      orderLineItem {
        id
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      status
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      data
      createdAt
      updatedAt
      bookedBoothEquipmentId
      boothEquipmentOrderLineItemId
      __typename
    }
  }
`;
export const listBoothEquipments = /* GraphQL */ `
  query ListBoothEquipments(
    $filter: ModelBoothEquipmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoothEquipments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookedBooth {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        qty
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        status
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        data
        createdAt
        updatedAt
        bookedBoothEquipmentId
        boothEquipmentOrderLineItemId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscountCode = /* GraphQL */ `
  query GetDiscountCode($id: ID!) {
    getDiscountCode(id: $id) {
      id
      code
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      discountType
      discountAmount
      discountPercentage
      maxUsage
      usageCount
      startDate
      endDate
      status
      restrictedProductIds
      canBeCombined
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDiscountCodes = /* GraphQL */ `
  query ListDiscountCodes(
    $filter: ModelDiscountCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        discountType
        discountAmount
        discountPercentage
        maxUsage
        usageCount
        startDate
        endDate
        status
        restrictedProductIds
        canBeCombined
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const discountCodesByCode = /* GraphQL */ `
  query DiscountCodesByCode(
    $code: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountCodesByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        discountType
        discountAmount
        discountPercentage
        maxUsage
        usageCount
        startDate
        endDate
        status
        restrictedProductIds
        canBeCombined
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTopic = /* GraphQL */ `
  query GetTopic($id: ID!) {
    getTopic(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      color
      topicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        topics {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        __typename
      }
      companies {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      topicGroupTopicsId
      __typename
    }
  }
`;
export const listTopics = /* GraphQL */ `
  query ListTopics(
    $filter: ModelTopicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTopics = /* GraphQL */ `
  query SearchTopics(
    $filter: SearchableTopicFilterInput
    $sort: [SearchableTopicSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTopicAggregationInput]
  ) {
    searchTopics(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTopicGroup = /* GraphQL */ `
  query GetTopicGroup($id: ID!) {
    getTopicGroup(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      color
      topics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTopicGroups = /* GraphQL */ `
  query ListTopicGroups(
    $filter: ModelTopicGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        color
        topics {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCMSInterests = /* GraphQL */ `
  query GetCMSInterests($id: ID!) {
    getCMSInterests(id: $id) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCMSInterests = /* GraphQL */ `
  query ListCMSInterests(
    $filter: ModelCMSInterestsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCMSNews = /* GraphQL */ `
  query GetCMSNews($id: ID!) {
    getCMSNews(id: $id) {
      id
      type
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      isHero
      createdAt
      updatedAt
      companyNewsId
      employeeNewsId
      cMSNewsEmployeeId
      cMSNewsTopicId
      owner
      __typename
    }
  }
`;
export const listCMSNews = /* GraphQL */ `
  query ListCMSNews(
    $filter: ModelCMSNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        name {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        previewImage {
          fileName
          alt
          x4UUID
          __typename
        }
        excerpt {
          language
          text
          __typename
        }
        interests {
          id
          createdAt
          updatedAt
          __typename
        }
        content {
          language
          text
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        adminComment
        approved
        status
        isHero
        createdAt
        updatedAt
        companyNewsId
        employeeNewsId
        cMSNewsEmployeeId
        cMSNewsTopicId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCMSNews = /* GraphQL */ `
  query SearchCMSNews(
    $filter: SearchableCMSNewsFilterInput
    $sort: [SearchableCMSNewsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCMSNewsAggregationInput]
  ) {
    searchCMSNews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        name {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        previewImage {
          fileName
          alt
          x4UUID
          __typename
        }
        excerpt {
          language
          text
          __typename
        }
        interests {
          id
          createdAt
          updatedAt
          __typename
        }
        content {
          language
          text
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        adminComment
        approved
        status
        isHero
        createdAt
        updatedAt
        companyNewsId
        employeeNewsId
        cMSNewsEmployeeId
        cMSNewsTopicId
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getNewsTicketNews = /* GraphQL */ `
  query GetNewsTicketNews($id: ID!) {
    getNewsTicketNews(id: $id) {
      id
      title {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      link
      excerpt {
        language
        text
        __typename
      }
      content {
        language
        text
        __typename
      }
      externalNewsId
      WPJetEngineId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNewsTicketNews = /* GraphQL */ `
  query ListNewsTicketNews(
    $filter: ModelNewsTicketNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsTicketNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        link
        excerpt {
          language
          text
          __typename
        }
        content {
          language
          text
          __typename
        }
        externalNewsId
        WPJetEngineId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const newsTicketNewsByExternalNewsId = /* GraphQL */ `
  query NewsTicketNewsByExternalNewsId(
    $externalNewsId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelNewsTicketNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    newsTicketNewsByExternalNewsId(
      externalNewsId: $externalNewsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        link
        excerpt {
          language
          text
          __typename
        }
        content {
          language
          text
          __typename
        }
        externalNewsId
        WPJetEngineId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchNewsTicketNews = /* GraphQL */ `
  query SearchNewsTicketNews(
    $filter: SearchableNewsTicketNewsFilterInput
    $sort: [SearchableNewsTicketNewsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNewsTicketNewsAggregationInput]
  ) {
    searchNewsTicketNews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        title {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        link
        excerpt {
          language
          text
          __typename
        }
        content {
          language
          text
          __typename
        }
        externalNewsId
        WPJetEngineId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getLecture = /* GraphQL */ `
  query GetLecture($id: ID!) {
    getLecture(id: $id) {
      id
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        orders {
          nextToken
          __typename
        }
        jetEngineAccess {
          baseurl
          username
          password
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      startTime
      endTime
      dateTimes
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      companyBooth
      speakers {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      speakersUsers {
        items {
          id
          lectureId
          userId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      session
      mainSpeaker {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      mainSpeakerUser {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      room
      title {
        language
        text
        __typename
      }
      subjects {
        id
        name {
          language
          text
          __typename
        }
        __typename
      }
      topics {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content {
        language
        text
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      presentationLanguage
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      pdfDe {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      pdfEn {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      videoS3Key {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      adminComment
      adminTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      approved
      approvedInsight
      lengthInSeconds
      status
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      courses {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarLecturesId
      industryLecturesId
      companyLecturesId
      eventLecturesId
      lectureMainSpeakerId
      lectureMainSpeakerUserId
      lectureAdminTopicId
      __typename
    }
  }
`;
export const listLectures = /* GraphQL */ `
  query ListLectures(
    $filter: ModelLectureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLectures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchLectures = /* GraphQL */ `
  query SearchLectures(
    $filter: SearchableLectureFilterInput
    $sort: [SearchableLectureSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableLectureAggregationInput]
  ) {
    searchLectures(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCMSProduct = /* GraphQL */ `
  query GetCMSProduct($id: ID!) {
    getCMSProduct(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      content {
        language
        text
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      type
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyProductsId
      cMSProductEmployeeId
      cMSProductTopicId
      owner
      __typename
    }
  }
`;
export const listCMSProducts = /* GraphQL */ `
  query ListCMSProducts(
    $filter: ModelCMSProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        images {
          __typename
        }
        previewImage {
          fileName
          alt
          x4UUID
          __typename
        }
        content {
          language
          text
          __typename
        }
        excerpt {
          language
          text
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        interests {
          id
          createdAt
          updatedAt
          __typename
        }
        type
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        adminComment
        approved
        status
        createdAt
        updatedAt
        companyProductsId
        cMSProductEmployeeId
        cMSProductTopicId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCMSProducts = /* GraphQL */ `
  query SearchCMSProducts(
    $filter: SearchableCMSProductFilterInput
    $sort: [SearchableCMSProductSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCMSProductAggregationInput]
  ) {
    searchCMSProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        images {
          __typename
        }
        previewImage {
          fileName
          alt
          x4UUID
          __typename
        }
        content {
          language
          text
          __typename
        }
        excerpt {
          language
          text
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        interests {
          id
          createdAt
          updatedAt
          __typename
        }
        type
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        adminComment
        approved
        status
        createdAt
        updatedAt
        companyProductsId
        cMSProductEmployeeId
        cMSProductTopicId
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCMSJobListing = /* GraphQL */ `
  query GetCMSJobListing($id: ID!) {
    getCMSJobListing(id: $id) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyJobsId
      cMSJobListingEmployeeId
      owner
      __typename
    }
  }
`;
export const listCMSJobListings = /* GraphQL */ `
  query ListCMSJobListings(
    $filter: ModelCMSJobListingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSJobListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        name {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        previewImage {
          fileName
          alt
          x4UUID
          __typename
        }
        interests {
          id
          createdAt
          updatedAt
          __typename
        }
        content {
          language
          text
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        adminComment
        approved
        status
        createdAt
        updatedAt
        companyJobsId
        cMSJobListingEmployeeId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCMSJobListings = /* GraphQL */ `
  query SearchCMSJobListings(
    $filter: SearchableCMSJobListingFilterInput
    $sort: [SearchableCMSJobListingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCMSJobListingAggregationInput]
  ) {
    searchCMSJobListings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        name {
          language
          text
          __typename
        }
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        previewImage {
          fileName
          alt
          x4UUID
          __typename
        }
        interests {
          id
          createdAt
          updatedAt
          __typename
        }
        content {
          language
          text
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        adminComment
        approved
        status
        createdAt
        updatedAt
        companyJobsId
        cMSJobListingEmployeeId
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCMSTour = /* GraphQL */ `
  query GetCMSTour($id: ID!) {
    getCMSTour(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      fromDate
      toDate
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      tourCompanyRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventToursId
      owner
      __typename
    }
  }
`;
export const listCMSTours = /* GraphQL */ `
  query ListCMSTours(
    $filter: ModelCMSTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCMSTourCompanyRegistration = /* GraphQL */ `
  query GetCMSTourCompanyRegistration($id: ID!) {
    getCMSTourCompanyRegistration(id: $id) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourCompanyRegistrationsId
      companyTourRegistrationsId
      employeeTourRegistrationsId
      cMSTourCompanyRegistrationCompanyId
      cMSTourCompanyRegistrationEmployeeId
      owner
      __typename
    }
  }
`;
export const listCMSTourCompanyRegistrations = /* GraphQL */ `
  query ListCMSTourCompanyRegistrations(
    $filter: ModelCMSTourCompanyRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSTourCompanyRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        date
        tour {
          id
          fromDate
          toDate
          createdAt
          updatedAt
          eventToursId
          owner
          __typename
        }
        status
        createdAt
        updatedAt
        cMSTourTourCompanyRegistrationsId
        companyTourRegistrationsId
        employeeTourRegistrationsId
        cMSTourCompanyRegistrationCompanyId
        cMSTourCompanyRegistrationEmployeeId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCMSTourVisitorRegistration = /* GraphQL */ `
  query GetCMSTourVisitorRegistration($id: ID!) {
    getCMSTourVisitorRegistration(id: $id) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourVisitorRegistrationsId
      companyTourVisitorRegistrationsId
      userTourRegistrationsId
      owner
      __typename
    }
  }
`;
export const listCMSTourVisitorRegistrations = /* GraphQL */ `
  query ListCMSTourVisitorRegistrations(
    $filter: ModelCMSTourVisitorRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMSTourVisitorRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        date
        tour {
          id
          fromDate
          toDate
          createdAt
          updatedAt
          eventToursId
          owner
          __typename
        }
        status
        createdAt
        updatedAt
        cMSTourTourVisitorRegistrationsId
        companyTourVisitorRegistrationsId
        userTourRegistrationsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVideoProgress = /* GraphQL */ `
  query GetVideoProgress($id: ID!) {
    getVideoProgress(id: $id) {
      id
      userId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      videoId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      progressInSeconds
      totalWatchedInSeconds
      createdAt
      updatedAt
      lectureVideoProgressId
      userVideoProgressId
      __typename
    }
  }
`;
export const listVideoProgresses = /* GraphQL */ `
  query ListVideoProgresses(
    $filter: ModelVideoProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoProgresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        videoId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        progressInSeconds
        totalWatchedInSeconds
        createdAt
        updatedAt
        lectureVideoProgressId
        userVideoProgressId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const videoProgressByUser = /* GraphQL */ `
  query VideoProgressByUser(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoProgressByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        videoId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        progressInSeconds
        totalWatchedInSeconds
        createdAt
        updatedAt
        lectureVideoProgressId
        userVideoProgressId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const videoProgressByVideoAndUser = /* GraphQL */ `
  query VideoProgressByVideoAndUser(
    $videoId: String!
    $userId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoProgressByVideoAndUser(
      videoId: $videoId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        videoId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        progressInSeconds
        totalWatchedInSeconds
        createdAt
        updatedAt
        lectureVideoProgressId
        userVideoProgressId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      participantsUsers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      participantsUserCogIds
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userChatsId
      __typename
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        participantsUsers {
          nextToken
          __typename
        }
        participantsUserCogIds
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChatsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      chatId
      chat {
        id
        participantsUsers {
          nextToken
          __typename
        }
        participantsUserCogIds
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChatsId
        __typename
      }
      participantsUserCogIds
      sender {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      message
      createdAt
      updatedAt
      chatMessagesId
      userMessagesId
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatId
        chat {
          id
          participantsUserCogIds
          createdAt
          updatedAt
          userChatsId
          __typename
        }
        participantsUserCogIds
        sender {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        message
        createdAt
        updatedAt
        chatMessagesId
        userMessagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const MessageByChat = /* GraphQL */ `
  query MessageByChat(
    $chatId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MessageByChat(
      chatId: $chatId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatId
        chat {
          id
          participantsUserCogIds
          createdAt
          updatedAt
          userChatsId
          __typename
        }
        participantsUserCogIds
        sender {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        message
        createdAt
        updatedAt
        chatMessagesId
        userMessagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIndustry = /* GraphQL */ `
  query GetIndustry($id: ID!) {
    getIndustry(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      subTitle {
        language
        text
        __typename
      }
      handle
      domain
      notificationEmailAddress
      primaryColor
      secondaryColor
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      favicon {
        fileName
        alt
        x4UUID
        __typename
      }
      sortKey
      status
      socialLinks {
        facebook
        x
        instagram
        spotify
        linkedin
        xing
        youtube
        pinterest
        snapchat
        tiktok
        __typename
      }
      events {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      orders {
        items {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      jetEngineAccess {
        baseurl
        username
        password
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIndustries = /* GraphQL */ `
  query ListIndustries(
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        orders {
          nextToken
          __typename
        }
        jetEngineAccess {
          baseurl
          username
          password
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const industriesByHandle = /* GraphQL */ `
  query IndustriesByHandle(
    $handle: String!
    $sortDirection: ModelSortDirection
    $filter: ModelIndustryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    industriesByHandle(
      handle: $handle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        orders {
          nextToken
          __typename
        }
        jetEngineAccess {
          baseurl
          username
          password
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      industry
      status
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      banner {
        fileName
        alt
        x4UUID
        __typename
      }
      departments {
        items {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      attendingEvents {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      employmentRequests {
        items {
          id
          initiator
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      admin {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      adminEmployees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      admins
      x4Id
      x4AdminId
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          type
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyProductsId
          cMSProductEmployeeId
          cMSProductTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyJobsId
          cMSJobListingEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      topics {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      billingContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      billingSettings {
        orderNumberRequired
        __typename
      }
      orders {
        items {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          createdByAdmin
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyAdminId
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCompanies = /* GraphQL */ `
  query SearchCompanies(
    $filter: SearchableCompanyFilterInput
    $sort: [SearchableCompanySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCompanyAggregationInput]
  ) {
    searchCompanies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      department {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions {
        resource
        actions
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      x4Id
      News {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      WPJetEngineId
      lectures {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      userCognitoId
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyEmployeesId
      companyAdminEmployeesId
      userEmployeeProfilesId
      __typename
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchEmployees = /* GraphQL */ `
  query SearchEmployees(
    $filter: SearchableEmployeeFilterInput
    $sort: [SearchableEmployeeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEmployeeAggregationInput]
  ) {
    searchEmployees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEmploymentRequest = /* GraphQL */ `
  query GetEmploymentRequest($id: ID!) {
    getEmploymentRequest(id: $id) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      requestingUser {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      initiator
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions
      status
      temporaryPassword
      newCognitoUserCreated
      createdAt
      updatedAt
      companyEmploymentRequestsId
      userEmploymentRequestsId
      owner
      __typename
    }
  }
`;
export const listEmploymentRequests = /* GraphQL */ `
  query ListEmploymentRequests(
    $filter: ModelEmploymentRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmploymentRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        requestingUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        initiator
        department {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions
        status
        temporaryPassword
        newCognitoUserCreated
        createdAt
        updatedAt
        companyEmploymentRequestsId
        userEmploymentRequestsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      owner
      id
      email
      firstName
      middleName
      lastName
      salutation
      title
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      birthday
      aboutMe {
        language
        text
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      personalIndustry
      personalDepartment
      employeeProfiles {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      pw_hash
      hasCompletedOnboarding
      conditions {
        id
        accepted
        acceptedAt
        __typename
      }
      employmentRequests {
        items {
          id
          initiator
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tickets {
        items {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        nextToken
        __typename
      }
      status
      orders {
        items {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      hasInsightAccess
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      chats {
        items {
          id
          participantsUserCogIds
          createdAt
          updatedAt
          userChatsId
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          lectureId
          userId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarSpeakersId
      chatParticipantsUsersId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      type
      boothBooking
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      date
      startDate
      endDate
      startTime
      endTime
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectureRooms
      lectureRegistrationStartDate
      lectureRegistrationEndDate
      lectureEditEndDate
      lectureDocumentsEndDate
      lectureRecordingCorrectionEndDate
      actionRegistrationStartDate
      actionRegistrationEndDate
      actionEditEndDate
      deadlineBoothEquipmentPrint
      startBoothEquipmentBooking
      deadlineBoothEquipmentBooking
      availableLectureTypes
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      landingPageUrl
      attendees {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableBundleBooths {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ticketProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      tours {
        items {
          id
          fromDate
          toDate
          createdAt
          updatedAt
          eventToursId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          createdByAdmin
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          type
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      availableCarpets {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      highlightedPersons {
        userid
        text {
          language
          text
          __typename
        }
        __typename
      }
      industries {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      WPJetEngineId
      bookingTicketBulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      hasInvitationCodes
      suggestedInvitationCode
      badgePreview {
        fileName
        alt
        x4UUID
        __typename
      }
      bookingSelectDays
      ticketTAC {
        language
        text
        __typename
      }
      ticketPrivacyPolicy {
        language
        text
        __typename
      }
      boothTAC {
        language
        text
        __typename
      }
      boothPrivacyPolicy {
        language
        text
        __typename
      }
      enableTicketBooking
      ticketCancellationDeadline
      ticketNotice {
        language
        text
        __typename
      }
      ticketOrganizerLine {
        language
        text
        __typename
      }
      bookingNotice {
        language
        text
        __typename
      }
      exhibitorLectures
      lecturesNotice {
        language
        text
        __typename
      }
      landingPageIntroText {
        language
        text
        __typename
      }
      landingPageProgramText {
        language
        text
        __typename
      }
      landingPageProgramSpeakersText {
        language
        text
        __typename
      }
      landingPageBookingText {
        language
        text
        __typename
      }
      landingPageBookingPandCText {
        language
        text
        __typename
      }
      createdAt
      updatedAt
      eventTicketProductId
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventCarpet = /* GraphQL */ `
  query GetEventCarpet($id: ID!) {
    getEventCarpet(id: $id) {
      id
      order
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      availableInEvents {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventCarpets = /* GraphQL */ `
  query ListEventCarpets(
    $filter: ModelEventCarpetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCarpets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employees {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      x4Name
      createdAt
      updatedAt
      companyDepartmentsId
      __typename
    }
  }
`;
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchDepartments = /* GraphQL */ `
  query SearchDepartments(
    $filter: SearchableDepartmentFilterInput
    $sort: [SearchableDepartmentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableDepartmentAggregationInput]
  ) {
    searchDepartments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getPrint = /* GraphQL */ `
  query GetPrint($id: ID!) {
    getPrint(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      dimensions
      type
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvailablePrintsId
      __typename
    }
  }
`;
export const listPrints = /* GraphQL */ `
  query ListPrints(
    $filter: ModelPrintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        dimensions
        type
        images {
          __typename
        }
        file {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        createdAt
        updatedAt
        bundleBoothAvailablePrintsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFloor = /* GraphQL */ `
  query GetFloor($id: ID!) {
    getFloor(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvaiableFloorsId
      __typename
    }
  }
`;
export const listFloors = /* GraphQL */ `
  query ListFloors(
    $filter: ModelFloorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFloors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        images {
          __typename
        }
        createdAt
        updatedAt
        bundleBoothAvaiableFloorsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMarketingPackage = /* GraphQL */ `
  query GetMarketingPackage($id: ID!) {
    getMarketingPackage(id: $id) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMarketingPackages = /* GraphQL */ `
  query ListMarketingPackages(
    $filter: ModelMarketingPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBookingBundleBooth = /* GraphQL */ `
  query GetBookingBundleBooth($id: ID!) {
    getBookingBundleBooth(id: $id) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      booth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      totalAmount
      printFiles {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floor {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        images {
          __typename
        }
        createdAt
        updatedAt
        bundleBoothAvaiableFloorsId
        __typename
      }
      accessories {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      marketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBookingBundleBooths = /* GraphQL */ `
  query ListBookingBundleBooths(
    $filter: ModelBookingBundleBoothFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingBundleBooths(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdBy {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        booth {
          id
          sku
          boothNumber
          price
          status
          createdAt
          updatedAt
          __typename
        }
        status
        totalAmount
        printFiles {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floor {
          id
          color
          createdAt
          updatedAt
          bundleBoothAvaiableFloorsId
          __typename
        }
        accessories {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        marketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        notes
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getToDo = /* GraphQL */ `
  query GetToDo($id: ID!) {
    getToDo(id: $id) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      title
      description
      status
      dueDate
      startDate
      priority
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      createdAt
      updatedAt
      companyTodosId
      userTodosId
      owner
      __typename
    }
  }
`;
export const listToDos = /* GraphQL */ `
  query ListToDos(
    $filter: ModelToDoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToDos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        title
        description
        status
        dueDate
        startDate
        priority
        createdBy {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        createdAt
        updatedAt
        companyTodosId
        userTodosId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVerificationCode = /* GraphQL */ `
  query GetVerificationCode($id: ID!) {
    getVerificationCode(id: $id) {
      id
      code
      userId
      type
      status
      expirationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listVerificationCodes = /* GraphQL */ `
  query ListVerificationCodes(
    $filter: ModelVerificationCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        userId
        type
        status
        expirationDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSystemDocumentSettings = /* GraphQL */ `
  query GetSystemDocumentSettings(
    $documentType: String!
    $industryId: String!
  ) {
    getSystemDocumentSettings(
      documentType: $documentType
      industryId: $industryId
    ) {
      documentType
      industryId
      name
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      companyName
      companyAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      companyContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      bank {
        accountHolder
        iban
        bic
        bankName
        __typename
      }
      taxid
      legalNotice {
        short
        html
        __typename
      }
      dueDays
      prefix
      digits
      lastNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSystemDocumentSettings = /* GraphQL */ `
  query ListSystemDocumentSettings(
    $documentType: String
    $industryId: ModelStringKeyConditionInput
    $filter: ModelSystemDocumentSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemDocumentSettings(
      documentType: $documentType
      industryId: $industryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        documentType
        industryId
        name
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        companyName
        companyAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        companyContact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        bank {
          accountHolder
          iban
          bic
          bankName
          __typename
        }
        taxid
        legalNotice {
          short
          html
          __typename
        }
        dueDays
        prefix
        digits
        lastNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!) {
    getLog(id: $id) {
      id
      timestamp
      type
      message
      user
      ip
      userAgent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        type
        message
        user
        ip
        userAgent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchLogs = /* GraphQL */ `
  query SearchLogs(
    $filter: SearchableLogFilterInput
    $sort: [SearchableLogSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableLogAggregationInput]
  ) {
    searchLogs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        timestamp
        type
        message
        user
        ip
        userAgent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getLecturesInCourses = /* GraphQL */ `
  query GetLecturesInCourses($id: ID!) {
    getLecturesInCourses(id: $id) {
      id
      courseId
      lectureId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLecturesInCourses = /* GraphQL */ `
  query ListLecturesInCourses(
    $filter: ModelLecturesInCoursesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLecturesInCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        lectureId
        course {
          id
          sortKey
          videos
          type
          duration
          price
          WPJetEngineId
          createdAt
          updatedAt
          __typename
        }
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesInCoursesByCourseId = /* GraphQL */ `
  query LecturesInCoursesByCourseId(
    $courseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesInCoursesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesInCoursesByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        lectureId
        course {
          id
          sortKey
          videos
          type
          duration
          price
          WPJetEngineId
          createdAt
          updatedAt
          __typename
        }
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesInCoursesByLectureId = /* GraphQL */ `
  query LecturesInCoursesByLectureId(
    $lectureId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesInCoursesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesInCoursesByLectureId(
      lectureId: $lectureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        lectureId
        course {
          id
          sortKey
          videos
          type
          duration
          price
          WPJetEngineId
          createdAt
          updatedAt
          __typename
        }
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoursesAcademyTopics = /* GraphQL */ `
  query GetCoursesAcademyTopics($id: ID!) {
    getCoursesAcademyTopics(id: $id) {
      id
      courseId
      academyTopicId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoursesAcademyTopics = /* GraphQL */ `
  query ListCoursesAcademyTopics(
    $filter: ModelCoursesAcademyTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesAcademyTopics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        academyTopicId
        course {
          id
          sortKey
          videos
          type
          duration
          price
          WPJetEngineId
          createdAt
          updatedAt
          __typename
        }
        academyTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const coursesAcademyTopicsByCourseId = /* GraphQL */ `
  query CoursesAcademyTopicsByCourseId(
    $courseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCoursesAcademyTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesAcademyTopicsByCourseId(
      courseId: $courseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        academyTopicId
        course {
          id
          sortKey
          videos
          type
          duration
          price
          WPJetEngineId
          createdAt
          updatedAt
          __typename
        }
        academyTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const coursesAcademyTopicsByAcademyTopicId = /* GraphQL */ `
  query CoursesAcademyTopicsByAcademyTopicId(
    $academyTopicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCoursesAcademyTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    coursesAcademyTopicsByAcademyTopicId(
      academyTopicId: $academyTopicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseId
        academyTopicId
        course {
          id
          sortKey
          videos
          type
          duration
          price
          WPJetEngineId
          createdAt
          updatedAt
          __typename
        }
        academyTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSeminarAcademyTopics = /* GraphQL */ `
  query GetSeminarAcademyTopics($id: ID!) {
    getSeminarAcademyTopics(id: $id) {
      id
      academyTopicId
      seminarId
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSeminarAcademyTopics = /* GraphQL */ `
  query ListSeminarAcademyTopics(
    $filter: ModelSeminarAcademyTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeminarAcademyTopics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        academyTopicId
        seminarId
        academyTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seminarAcademyTopicsByAcademyTopicId = /* GraphQL */ `
  query SeminarAcademyTopicsByAcademyTopicId(
    $academyTopicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSeminarAcademyTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seminarAcademyTopicsByAcademyTopicId(
      academyTopicId: $academyTopicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        academyTopicId
        seminarId
        academyTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const seminarAcademyTopicsBySeminarId = /* GraphQL */ `
  query SeminarAcademyTopicsBySeminarId(
    $seminarId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSeminarAcademyTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seminarAcademyTopicsBySeminarId(
      seminarId: $seminarId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        academyTopicId
        seminarId
        academyTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrderProducts = /* GraphQL */ `
  query GetOrderProducts($id: ID!) {
    getOrderProducts(id: $id) {
      id
      productId
      orderId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      order {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        industryOrdersId
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        orderId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderProductsByProductId = /* GraphQL */ `
  query OrderProductsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderProductsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        orderId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderProductsByOrderId = /* GraphQL */ `
  query OrderProductsByOrderId(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrderProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderProductsByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        orderId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        order {
          id
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          industryOrdersId
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAvailableProductsInIndustries = /* GraphQL */ `
  query GetAvailableProductsInIndustries($id: ID!) {
    getAvailableProductsInIndustries(id: $id) {
      id
      productId
      industryId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        orders {
          nextToken
          __typename
        }
        jetEngineAccess {
          baseurl
          username
          password
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAvailableProductsInIndustries = /* GraphQL */ `
  query ListAvailableProductsInIndustries(
    $filter: ModelAvailableProductsInIndustriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailableProductsInIndustries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        industryId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableProductsInIndustriesByProductId = /* GraphQL */ `
  query AvailableProductsInIndustriesByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableProductsInIndustriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableProductsInIndustriesByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        industryId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableProductsInIndustriesByIndustryId = /* GraphQL */ `
  query AvailableProductsInIndustriesByIndustryId(
    $industryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableProductsInIndustriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableProductsInIndustriesByIndustryId(
      industryId: $industryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        industryId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAvailableProductsInEvents = /* GraphQL */ `
  query GetAvailableProductsInEvents($id: ID!) {
    getAvailableProductsInEvents(id: $id) {
      id
      productId
      eventId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        name_text
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAvailableProductsInEvents = /* GraphQL */ `
  query ListAvailableProductsInEvents(
    $filter: ModelAvailableProductsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailableProductsInEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        eventId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableProductsInEventsByProductId = /* GraphQL */ `
  query AvailableProductsInEventsByProductId(
    $productId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableProductsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableProductsInEventsByProductId(
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        eventId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableProductsInEventsByEventId = /* GraphQL */ `
  query AvailableProductsInEventsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableProductsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableProductsInEventsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        eventId
        product {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAvailableBundleBoothsInEvents = /* GraphQL */ `
  query GetAvailableBundleBoothsInEvents($id: ID!) {
    getAvailableBundleBoothsInEvents(id: $id) {
      id
      bundleBoothId
      eventId
      bundleBooth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAvailableBundleBoothsInEvents = /* GraphQL */ `
  query ListAvailableBundleBoothsInEvents(
    $filter: ModelAvailableBundleBoothsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailableBundleBoothsInEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bundleBoothId
        eventId
        bundleBooth {
          id
          sku
          boothNumber
          price
          status
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableBundleBoothsInEventsByBundleBoothId = /* GraphQL */ `
  query AvailableBundleBoothsInEventsByBundleBoothId(
    $bundleBoothId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableBundleBoothsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableBundleBoothsInEventsByBundleBoothId(
      bundleBoothId: $bundleBoothId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bundleBoothId
        eventId
        bundleBooth {
          id
          sku
          boothNumber
          price
          status
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableBundleBoothsInEventsByEventId = /* GraphQL */ `
  query AvailableBundleBoothsInEventsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableBundleBoothsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableBundleBoothsInEventsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bundleBoothId
        eventId
        bundleBooth {
          id
          sku
          boothNumber
          price
          status
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompaniesTopics = /* GraphQL */ `
  query GetCompaniesTopics($id: ID!) {
    getCompaniesTopics(id: $id) {
      id
      topicId
      companyId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompaniesTopics = /* GraphQL */ `
  query ListCompaniesTopics(
    $filter: ModelCompaniesTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniesTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topicId
        companyId
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesTopicsByTopicId = /* GraphQL */ `
  query CompaniesTopicsByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompaniesTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesTopicsByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        companyId
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesTopicsByCompanyId = /* GraphQL */ `
  query CompaniesTopicsByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompaniesTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesTopicsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        companyId
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLecturesTopics = /* GraphQL */ `
  query GetLecturesTopics($id: ID!) {
    getLecturesTopics(id: $id) {
      id
      topicId
      lectureId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLecturesTopics = /* GraphQL */ `
  query ListLecturesTopics(
    $filter: ModelLecturesTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLecturesTopics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topicId
        lectureId
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesTopicsByTopicId = /* GraphQL */ `
  query LecturesTopicsByTopicId(
    $topicId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesTopicsByTopicId(
      topicId: $topicId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        lectureId
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesTopicsByLectureId = /* GraphQL */ `
  query LecturesTopicsByLectureId(
    $lectureId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesTopicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesTopicsByLectureId(
      lectureId: $lectureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        topicId
        lectureId
        topic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLecturesSpeakers = /* GraphQL */ `
  query GetLecturesSpeakers($id: ID!) {
    getLecturesSpeakers(id: $id) {
      id
      lectureId
      employeeId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const listLecturesSpeakers = /* GraphQL */ `
  query ListLecturesSpeakers(
    $filter: ModelLecturesSpeakersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLecturesSpeakers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lectureId
        employeeId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        createdAt
        updatedAt
        userCognitoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesSpeakersByLectureId = /* GraphQL */ `
  query LecturesSpeakersByLectureId(
    $lectureId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesSpeakersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesSpeakersByLectureId(
      lectureId: $lectureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lectureId
        employeeId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        createdAt
        updatedAt
        userCognitoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesSpeakersByEmployeeId = /* GraphQL */ `
  query LecturesSpeakersByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesSpeakersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesSpeakersByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lectureId
        employeeId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        createdAt
        updatedAt
        userCognitoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLecturesSpeakerUsers = /* GraphQL */ `
  query GetLecturesSpeakerUsers($id: ID!) {
    getLecturesSpeakerUsers(id: $id) {
      id
      lectureId
      userId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        speakersUsers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        mainSpeakerUser {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureMainSpeakerUserId
        lectureAdminTopicId
        __typename
      }
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLecturesSpeakerUsers = /* GraphQL */ `
  query ListLecturesSpeakerUsers(
    $filter: ModelLecturesSpeakerUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLecturesSpeakerUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lectureId
        userId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesSpeakerUsersByLectureId = /* GraphQL */ `
  query LecturesSpeakerUsersByLectureId(
    $lectureId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesSpeakerUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesSpeakerUsersByLectureId(
      lectureId: $lectureId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lectureId
        userId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lecturesSpeakerUsersByUserId = /* GraphQL */ `
  query LecturesSpeakerUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLecturesSpeakerUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lecturesSpeakerUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lectureId
        userId
        lecture {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureMainSpeakerUserId
          lectureAdminTopicId
          __typename
        }
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getIndustryEvents = /* GraphQL */ `
  query GetIndustryEvents($id: ID!) {
    getIndustryEvents(id: $id) {
      id
      industryId
      eventId
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        orders {
          nextToken
          __typename
        }
        jetEngineAccess {
          baseurl
          username
          password
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listIndustryEvents = /* GraphQL */ `
  query ListIndustryEvents(
    $filter: ModelIndustryEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndustryEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        industryId
        eventId
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const industryEventsByIndustryId = /* GraphQL */ `
  query IndustryEventsByIndustryId(
    $industryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIndustryEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    industryEventsByIndustryId(
      industryId: $industryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        industryId
        eventId
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const industryEventsByEventId = /* GraphQL */ `
  query IndustryEventsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIndustryEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    industryEventsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        industryId
        eventId
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompaniesAttendingEvents = /* GraphQL */ `
  query GetCompaniesAttendingEvents($id: ID!) {
    getCompaniesAttendingEvents(id: $id) {
      id
      companyId
      eventId
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCompaniesAttendingEvents = /* GraphQL */ `
  query ListCompaniesAttendingEvents(
    $filter: ModelCompaniesAttendingEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompaniesAttendingEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        eventId
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesAttendingEventsByCompanyId = /* GraphQL */ `
  query CompaniesAttendingEventsByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompaniesAttendingEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesAttendingEventsByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        eventId
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesAttendingEventsByEventId = /* GraphQL */ `
  query CompaniesAttendingEventsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCompaniesAttendingEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesAttendingEventsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        eventId
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmployeeDepartments = /* GraphQL */ `
  query GetEmployeeDepartments($id: ID!) {
    getEmployeeDepartments(id: $id) {
      id
      employeeId
      departmentId
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const listEmployeeDepartments = /* GraphQL */ `
  query ListEmployeeDepartments(
    $filter: ModelEmployeeDepartmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        departmentId
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        department {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        createdAt
        updatedAt
        userCognitoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employeeDepartmentsByEmployeeId = /* GraphQL */ `
  query EmployeeDepartmentsByEmployeeId(
    $employeeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeDepartmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeDepartmentsByEmployeeId(
      employeeId: $employeeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        departmentId
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        department {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        createdAt
        updatedAt
        userCognitoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employeeDepartmentsByDepartmentId = /* GraphQL */ `
  query EmployeeDepartmentsByDepartmentId(
    $departmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmployeeDepartmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employeeDepartmentsByDepartmentId(
      departmentId: $departmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeId
        departmentId
        employee {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        department {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        createdAt
        updatedAt
        userCognitoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAvailableCarpetsInEvents = /* GraphQL */ `
  query GetAvailableCarpetsInEvents($id: ID!) {
    getAvailableCarpetsInEvents(id: $id) {
      id
      eventId
      eventCarpetId
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureEditEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        actionRegistrationStartDate
        actionRegistrationEndDate
        actionEditEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          name_text
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        suggestedInvitationCode
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketCancellationDeadline
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        landingPageIntroText {
          language
          text
          __typename
        }
        landingPageProgramText {
          language
          text
          __typename
        }
        landingPageProgramSpeakersText {
          language
          text
          __typename
        }
        landingPageBookingText {
          language
          text
          __typename
        }
        landingPageBookingPandCText {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      eventCarpet {
        id
        order
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAvailableCarpetsInEvents = /* GraphQL */ `
  query ListAvailableCarpetsInEvents(
    $filter: ModelAvailableCarpetsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailableCarpetsInEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventCarpetId
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        eventCarpet {
          id
          order
          color
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableCarpetsInEventsByEventId = /* GraphQL */ `
  query AvailableCarpetsInEventsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableCarpetsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableCarpetsInEventsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventCarpetId
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        eventCarpet {
          id
          order
          color
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const availableCarpetsInEventsByEventCarpetId = /* GraphQL */ `
  query AvailableCarpetsInEventsByEventCarpetId(
    $eventCarpetId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAvailableCarpetsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availableCarpetsInEventsByEventCarpetId(
      eventCarpetId: $eventCarpetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventCarpetId
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureEditEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          actionRegistrationStartDate
          actionRegistrationEndDate
          actionEditEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          suggestedInvitationCode
          bookingSelectDays
          enableTicketBooking
          ticketCancellationDeadline
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        eventCarpet {
          id
          order
          color
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
